import React from 'react';
import FirstSection from "../Home/children/firstSection";
import {convertTime12to24, formatNumber} from "../../helper";
import {updateCart} from "../../actions/cart";
import {Cookies, withCookies} from "react-cookie";
import {withSnackbar} from "notistack";
import {connect} from "react-redux";
import {instanceOf} from "prop-types";
import CartItems from "../Checkout/cartItems";
import API from "../../api";

const api = new API();


class ProductGrid extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            default_store: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            delivery_address: this.props.cookies.get('delivery_address') ? this.props.cookies.get('delivery_address')?.['address'] : null,
            apartment: this.props.cookies.get('delivery_address') ? this.props.cookies.get('delivery_address')?.['apartment'] : null,
            zip_code: this.props.cookies.get('delivery_address') ? this.props.cookies.get('delivery_address')?.['zip_code'] : null,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            orgPrice: 0,
            hidden: true,
            order_date: this.props.cookies.get("date")
                ? this.props.cookies.get("date")
                : "",
            order_time: this.props.cookies.get("time")
                ? this.props.cookies.get("time")
                : "",
            order_note: this.props.cookies.get("order_note")
                ? this.props.cookies.get("order_note")
                : "",
        };
        this.checkoutHandler = this.checkoutHandler.bind(this);
        this.handler = this.handler.bind(this);
        this.stockHandler = this.stockHandler.bind(this);
        this.handleCheckout = this.handleCheckout.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }
        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['address'] !== this.state.delivery_address) {
            this.setState({delivery_address: this.props.cookies.get('delivery_address')?.['address']});
        }
        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['apartment'] !== this.state.apartment) {
            this.setState({apartment: this.props.cookies.get('delivery_address')?.['apartment']});
        }
        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['zip_code'] !== this.state.zip_code) {
            this.setState({zip_code: this.props.cookies.get('delivery_address')?.['zip_code']});
        }

        this.setState({
            items: this.props.cartAddedItems?.cart_items
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.cookies.get("toggle") && this.props.cookies.get("toggle") !== undefined && this.props.cookies.get("toggle") === "toggled") {
            this.props.cookies.set("toggle", "");
        }
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }
        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }
        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['address'] !== this.state.delivery_address) {
            this.setState({delivery_address: this.props.cookies.get('delivery_address')?.['address']});
        }
        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['apartment'] !== this.state.apartment) {
            this.setState({apartment: this.props.cookies.get('delivery_address')?.['apartment']});
        }
        if (this.props.cookies.get('delivery_address') !== undefined && this.props.cookies.get('delivery_address')?.['zip_code'] !== this.state.zip_code) {
            this.setState({zip_code: this.props.cookies.get('delivery_address')?.['zip_code']});
        }
    }

    handleCheckout() {
        if (parseInt(this.state.default_preference) === 2) {
            this.checkDeliveryEstimations();
        } else {
            this.props.history.push({
                pathname: "/checkout",
                state: {
                    date: this.state.order_date,
                    time: this.state.order_time,
                    order_note: this.state.order_note,
                },
            });
        }
    }

    checkoutHandler(val) {
        if (this.state.price !== val.price) {
            this.setState({
                orgPrice: val.price_with_charges ? val.price_with_charges : 0,
            });
        }
    }

    checkDeliveryEstimations() {

        const cart = this.props.cookies.get("cart_id") ? this.props.cookies.get("cart_id") : "";
        const selectedDate = this.props.cookies.get("date") ? this.props.cookies.get("date") : "";
        const selectedTime = this.props.cookies.get("time") ? this.props.cookies.get("time") : "";
        const selectedTimestamp = convertTime12to24(selectedDate, selectedTime.split('-')?.[0]);

        if (cart && selectedTimestamp && parseInt(this.state.default_preference) === 2) {
            var data = {
                "address": this.props.cookies.get('delivery_address')?.['address'],
                "apartment": this.props.cookies.get('delivery_address')?.['apartment'],
                "zipCode": this.props.cookies.get('delivery_address')?.['zip_code'],
                "cart_id": cart,
                "restaurant_id": this.state.default_store,
                "delivery_time": selectedTimestamp
            };
            api.deliveryEstimations(data).then((success) => {

                const {cookies} = this.props;
                const external_quote_id = success?.data?.response?.body?.id ?? "";
                cookies.set("external_quote_id", external_quote_id);
                this.props.updateCartItems({
                    cart_id: this.props.cookies.get("cart_id")
                        ? this.props.cookies.get("cart_id")
                        : "blank",
                    token: this.props.cookies.get("token")
                        ? this.props.cookies.get("token")
                        : null,
                });

                this.props.history.push({
                    pathname: "/checkout",
                    state: {
                        date: this.state.order_date,
                        time: this.state.order_time,
                        order_note: this.state.order_note,
                    },
                });
            }).catch((error) => {
                this.props.cookies.set("time", "");
                this.props.enqueueSnackbar(error.response.data.message?.message, {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                });
            });
        }
    }

    handler() {
        //null handler
    }

    stockHandler() {
        //null handler
    }

    render() {
        return (
            <React.Fragment>
                <section className="full-grid mb-5 pb-5 pb-md-0 mb-md-4">
                    <div className="col-12 list-items-main">
                        <div className="container checkout-view">
                            <div className="row">
                                <div className="col-12 mx-auto px-0">
                                    <div className="title mt-3 mb-4 d-flex align-items-center justify-content-between">
                                        <h2 className="mb-0">You may also like</h2>
                                        <div className="proceed-button d-none d-md-none d-lg-block d-xl-block">
                                            <button
                                                className="btn btn-proceed px-4"
                                                onClick={this.handleCheckout}
                                            >
                                                Proceed to checkout
                                            </button>
                                        </div>
                                    </div>
                                    <FirstSection preference={this.state.default_preference}
                                                  products={this.state.items}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.cartAddedItems !== undefined &&
                    this.props.cartAddedItems.cart_items !== undefined &&
                    this.props.cartAddedItems.cart_items.length > 0 ? (
                        <div className="col-12 foot-cart">
                            <div className="container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-sm-auto text-sm-left text-center">
                                        {parseInt(this.state.default_preference) === 1 &&
                                        <p className="mb-0">
                                            <small>Pickup from: </small>
                                            <b>{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"}</b>
                                        </p>
                                        }
                                        {parseInt(this.state.default_preference) === 2 &&
                                        <p className="mb-0">
                                            <small>Delivery to: </small>
                                            <b>
                                                {this.state.delivery_address?.substring(0, this.state.delivery_address.indexOf(',')) + (this.state.apartment ? ", " + this.state.apartment : "") +
                                                this.state.delivery_address?.substring(this.state.delivery_address.indexOf(',') + 1) + " " + this.state.zip_code}
                                            </b>
                                        </p>
                                        }
                                    </div>
                                    <div className="col-sm-auto proceed-button text-sm-left text-center">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-auto mb-2 mb-md-0">
                                                <h2 className="mb-0 d-flex align-items-center justify-content-between">
                                                    <span className="d-block">Estimated total</span> <span
                                                    className="d-none d-md-block px-1">:</span>
                                                    <b className="w-auto">{formatNumber(this.state.orgPrice)}</b>
                                                </h2>
                                            </div>
                                            <div className="col-12 col-md-auto ">
                                                <button
                                                    onClick={this.handleCheckout}
                                                    className="btn btn-proceed px-4 w-100"
                                                >
                                                    Proceed to checkout
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        " "
                    )}
                </section>
                <CartItems show={false} checkoutOnly={this.checkoutHandler} readOnly={this.handler}
                           stock={this.stockHandler}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) =>
            dispatch(updateCart({type: "UPDATE_CART_ITEMS", data: val})),
    };
};


export default withCookies(
    withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ProductGrid))
);

