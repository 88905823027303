import React from "react";
import {images} from "../../constant";
import {NavLink, withRouter} from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {withSnackbar} from "notistack";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import Popover from "@material-ui/core/Popover/Popover";
import {updateCart, updateList} from "../../actions/cart";
import {connect} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import API from "../../api";
import DeliveryOfflineModal from "./DeliveryOfflineModal";
import {limitToLast, onChildAdded, query, ref} from "@firebase/database";
import db from "../../../firebase";
import LazyLoad from "react-lazyload";
import CartDropDown from "./CartDropDown";

const api = new API();
let timerId;


class CateCard extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        (this.state = {
            quantity_product: null,
            show: false,
            toggled: "toggled",
            variable_id: null,
            quantity: 1,
            removed: 0,
            anchorEl: null,
            mode: 0,
            isAdded: 0,
            change: null,
            price: null,
            item_price: null,
            selected: null,
            showDeliveryOfflineConflictModal : false,
            cartItems: [],
            default_store: this.props.cookies.get('selected_store') ? this.props.cookies.get('selected_store') : 2,
            list: [],
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            firebase_stock : null,
            list_product : null
        });
        this.handleRedirection = this.handleRedirection.bind(this);
        this.selectHandler = this.selectHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        if (this.props.cartAddedItems?.cart_items != null) {
            this.setState({
                cartItems: this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items : [],
            });
        }

        if (this.props.cookies.get("token") && this.props.cookies.get("token") !== "" && this.props.list != null) {
            if (this.state.list !== this.props.list?.managelists) {
                this.setState({
                    list: this.props.list?.managelists?.length > 0 ? this.props.list?.managelists : [],
                });
            }
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        this.firebaseLastSnap();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        if (this.props.cartAddedItems?.cart_items != null) {
            if (prevProps.cartAddedItems?.cart_items !== this.props.cartAddedItems?.cart_items) {
                this.setState({
                    cartItems: this.props.cartAddedItems?.cart_items,
                    product_quantity: null,
                    remove_disabled: false
                });
            }
        }

        if (this.props.cookies.get("token") && this.props.cookies.get("token") !== "" && this.props.list != null) {
            if (prevProps.list?.managelists !== this.props.list?.managelists) {
                this.setState({list: this.props.list?.managelists?.length > 0 ? this.props.list?.managelists : []});
            }
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }
    }

    firebaseLastSnap() {
        const newMessageRef = query(ref(db, `item_stock_logs`),
            limitToLast(1)
        );
        onChildAdded(newMessageRef, (snapshot) => {
            const data = snapshot.val();
            if(data?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) {
                this.setState({firebase_stock: data},()=>{
                    this.props.updateCartItems({
                        cart_id: this.props.cookies.get("cart_id")
                            ? this.props.cookies.get("cart_id")
                            : "blank",
                        token: this.props.cookies.get("token")
                            ? this.props.cookies.get("token")
                            : null,
                    });
                })
            }
        });
    }

    handleRedirection = (path) => {
        this.props.history.push(path);
    };

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null},()=>{
            setTimeout(()=>{
                this.setState({list_product : null})
            },1000)
        });
    };


    handleList(val) {
        const product = {
            product_id: val.item_id,
            variable_id: val.variables[0].id,
            quantity: 1,
            amount: val.variables[0].sell_price,
            customization: '',
            list_unique_id: this.props.cookies.get("list_unique_id") ? this.props.cookies.get("list_unique_id") : "default",
            list_type_id: this.props.cookies.get("list_type_id") ? this.props.cookies.get("list_type_id") : "default",
        };

        api.addToList(
            product,
            this.props.cookies.get("token") ? this.props.cookies.get("token") : null
        )
            .then((success) => {
                if (success.data.status == "400") {
                    this.props.enqueueSnackbar(success.data.message, {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    });
                } else {
                    this.setState({list_product :  product?.product_id});
                    this.props.cookies.set("list_unique_id", success.data.response.list_unique_id);
                    this.props.cookies.set("list_type_id", success.data.response.list_id);
                    this.handleClose();
                }
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error?.response?.data?.message, {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                });
            });
    }

    addToCart(val) {

        var time = 250;

        var allowRequest = true;
        if (val.quantity <= 0) {
            this.setState({product_quantity: 0, remove_disabled: true}, () => {
                this.handleRemove(this.state.removed_item);
            });
            allowRequest = false;
        } else {
            this.setState({product_quantity: val.quantity, quantity_product: val.id});
        }

        clearTimeout(timerId);

        //api calling with 250ms delay
        timerId = setTimeout(
            function () {

                if (allowRequest === true) {

                    this.setState({
                        show: this.props.cookies.get("change_store") && this.props.cookies.get("change_store") === "false" ? false : true,
                    });

                    this.setState({price: val.sell_price, item_price: val.item_price});

                    const product = {
                        menu_id: 3,
                        restaurant: this.state.default_store,
                        item_id: val.id,
                        variable_id: val.variable_id,
                        quantity: this.state.product_quantity ? this.state.product_quantity : 1,
                        customization: val.customize === 'No preference' ? '' : (val.customize || '')
                    };

                    api.addToCart(
                        product,
                        this.props.cookies.get("cart_id") ? this.props.cookies.get("cart_id") : "blank",
                        this.props.cookies.get("token") ? this.props.cookies.get("token") : null,
                        this.state.default_preference
                    )
                        .then((success) => {
                            if (success.data.status == "400") {
                                this.setState({errors: {}});
                                this.props.enqueueSnackbar(success.data.message, {
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                });
                            } else if (success.data.status == "402") {
                                confirmAlert({
                                    title: "Other restaurant product already exist",
                                    message: "Are you sure, want to  add this.",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                this.setState({removed: 1}, () => {
                                                    this.addToCart(val);
                                                });
                                            },
                                        },
                                        {
                                            label: "No",
                                        },
                                    ],
                                });
                            }  else if (success.data.status == "405") {
                                this.setState({showDeliveryOfflineConflictModal : true})
                            } else {
                                this.props.cookies.set("cart_id", success.data.response);
                                this.setState({removed: 0});
                                this.props.updateCartItems({
                                    cart_id: this.props.cookies.get("cart_id")
                                        ? this.props.cookies.get("cart_id")
                                        : "blank",
                                    token: this.props.cookies.get("token")
                                        ? this.props.cookies.get("token")
                                        : null,
                                });
                            }
                        })
                        .catch((error) => {
                            if (error?.response?.data?.response?.is_grocery_online === 0) {
                                this.setState({offline: true});
                            } else {
                                this.props.enqueueSnackbar(error?.response?.data?.message, {
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                });
                            }
                        });
                }
            }
                .bind(this),
            time
        );
    }

    handleRemove = (item_id) => {
        api.removeFromCart(item_id).then((success) => {
            this.setState({
                price : null,
                product_quantity: null,
            });
            this.props.updateCartItems({
                cart_id: this.props.cookies.get("cart_id")
                    ? this.props.cookies.get("cart_id")
                    : "blank",
                token: this.props.cookies.get("token")
                    ? this.props.cookies.get("token")
                    : null,
            });
        });
    };

    selectHandler(event, customize = '') {
        let item = JSON.parse(event.target.value);
        this.addToCart({
            variable_id: item.id,
            item_price: item.item_price,
            sell_price: item.sell_price,
            id: item.item_id,
            quantity: item.item_count ? item.item_count : 1,
            customize: customize === 'No preference' ? '' : customize,
        });
    }

    render() {

        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;

        const price_range = this.props.val?.item_variables?.length
            ? this.props.val.item_variables.filter(product => product.status === 1)
                .map(product => product.sell_price)
                .filter(price => price !== undefined)
            : [];

        const min = price_range.length ? Math.min(...price_range).toFixed(2) : 0;

        const realTimeoutStock = (this.state.firebase_stock && this.state.firebase_stock?.item_id == this.props.val.item_id && (this.state.firebase_stock?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) )
            ? this.state.firebase_stock?.status == 0 : (this.props.val?.restaurant_items?.length > 0 && this.props.val?.restaurant_items?.[0]?.status !== 1);

        return (
            <React.Fragment>
                <div className="food-column"
                     data-aos="zoom-in"
                     data-aos-easing="linear"
                >
                    <div className="product-details">
                        {this.props.val.is_on_sell == "1" ? (
                            <div className="onsale-details">
                                <span>On sale</span>
                            </div>
                        ) : null}
                        <div className="top-img d-none">
                            {this.props.val.is_prime == "1" ? (
                                <Tippy content="Prime item">
                                    <img className="p" src={images.imgP} alt="Not Found"/>
                                </Tippy>
                            ) : null}

                            {this.props.val.is_available_in_bulk == "1" ? (
                                <Tippy content="Bulk item">
                                    <img className="b" src={images.imgB} alt="Not Found"/>
                                </Tippy>
                            ) : null}
                        </div>
                        <NavLink exact to={`/catering/${this.props.val.slug}`}>
                            <div className="food-img">
                                <LazyLoad key={this.props.val.item_id} once>
                                    <img
                                        src={this.props.val.images.length > 0 ? `https://image.qualwebs.com/211x211/${this.props.val.images[0].path}` : images.food}
                                        className="img-fluid"
                                        alt={this.props.val?.item_name}
                                    />
                                </LazyLoad>
                            </div>
                        </NavLink>

                        <div className="food-content">
                            <a
                                href="javascript:void(0)"
                                onClick={() =>
                                    this.handleRedirection(`/catering/${this.props.val.slug}`)
                                }
                            >
                                <h6>{this.props.val?.item_name}</h6>
                            </a>
                            <a
                                href="javascript:void(0)"
                                onClick={() =>
                                    this.handleRedirection(`/catering/${this.props.val.slug}`)
                                }
                            >
                                <p className="price">
                                    <span>
                                        ${this.state.price
                                        ? Number(this.state.price).toFixed(2)
                                        : Number(min).toFixed(2)}{" "}
                                    </span>
                                </p>
                            </a>

                            <div className="buttons mt-1">
                                {realTimeoutStock ?
                                    <div className="buttons mt-1">
                                            <span className="deliverable d-inline-block w-100">
                                                 <p className="">Out-Of-Stock</p>
                                            </span>
                                    </div>
                                :
                                parseInt(this.state.default_preference) === 2 && this.props.val?.is_deliverable === 0 ?
                                    <div className="deliverable d-none d-md-block">
                                        <p className="">Available for Pickup Only</p>
                                    </div>
                                    :
                                    this.state.cartItems.find(
                                        (item) => item.item_id === this.props.val.item_id
                                    ) ? (
                                        <>
                                            {this.state.cartItems
                                                .filter((item) => item.item_id === this.props.val.item_id)
                                                .slice(0,1)
                                                .map((val,index) => {
                                                    const allCartItems = this.state.cartItems.filter((item) => item.item_id === this.props.val.item_id);
                                                    const totalItems = allCartItems.length;
                                                    var found = this.props.val.item_variables?.find(o => parseInt(val.variable_id) === parseInt(o.id));
                                                    return (this.props.val.item_variables?.length > 1 || !!this.props.val.customizations?.length) ? (
                                                        <>
                                                            <CartDropDown
                                                                item={this.props.val.item_id}
                                                                variables={this.props.val.item_variables || []}
                                                                preferences={this.props.val.customizations || []}
                                                                handleVariables={this.selectHandler}
                                                                btnLabel={found ? `${totalItems} added` : "Add to cart"}
                                                                cartValue={val}
                                                                isCatering={true}
                                                                isAddedToCart={true}
                                                                productName={this.props.val?.item_name}
                                                                itemId={this.props.val?.item_id}
                                                            />

                                                            <div className="added" key={val.item_id}>
                                                          <span
                                                              className="minus"
                                                              onClick={() => {
                                                                  if((this.props.val.item_variables?.length > 1 || !!this.props.val.customizations?.length)) {
                                                                      const ele = document.getElementById('dropdownButton' + this.props.val?.item_id);
                                                                      if(!!ele) {
                                                                          ele.click();
                                                                      }
                                                                  } else {
                                                                      this.handleRemove(val.cart_item_id);
                                                                  }
                                                              }}
                                                          >
                                                              {(this.props.val.item_variables?.length > 1 || !!this.props.val.customizations?.length) ?
                                                                  <i className="fa fa-trash"></i>
                                                                  :
                                                                  (this.state.quantity_product === val.item_id && this.state.product_quantity !== null && this.state.product_quantity ? this.state.product_quantity : val.item_count) === 1
                                                                      ? <i className="fa fa-trash"></i> :
                                                                      <i className="fa fa-minus"></i>
                                                              }
                                                          </span>
                                                            </div>
                                                        </>
                                                    ) : (

                                                        <>
                                                            <p className="d-none">{found ? found?.tray_size : "Add to cart"}</p>

                                                            <button className="btn btn-m cart-button menu-toggle"
                                                                    style={{overflow: "visible"}}>
                                                                <div className="added" key={val.item_id}>
                                                                <span
                                                                    className={(this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ? "minus disabled" : "minus"}
                                                                    onClick={() => this.setState({removed_item: val.cart_item_id}, () => {

                                                                        if (((this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1) < 0) {
                                                                            this.setState({remove_disabled: true}, () => {
                                                                                this.handleRemove(val.cart_item_id);
                                                                            });
                                                                        } else {
                                                                            this.addToCart({
                                                                                id: val.item_id,
                                                                                variable_id: val.variable_id,
                                                                                item_price: this.state.item_price,
                                                                                sell_price: this.state.sell_price,
                                                                                quantity: ((this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1),
                                                                            })
                                                                        }
                                                                    })
                                                                    }
                                                                >
                                                                {
                                                                    (this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) === 1 ?
                                                                        <i className="fa fa-trash"></i> :
                                                                        <i className="fa fa-minus"></i>
                                                                }
                                                              </span>

                                                                    <span>
                                                                  {
                                                                      (this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ?
                                                                          <i className="fa fa-times"></i> :
                                                                          (this.state.product_quantity ? this.state.product_quantity : val.item_count) + " added"
                                                                  }
                                                               </span>

                                                                    <span
                                                                        className={(this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ? "plus disabled" : "plus"}
                                                                        onClick={() =>
                                                                            this.addToCart({
                                                                                id: val.item_id,
                                                                                variable_id: val.variable_id,
                                                                                item_price: this.state.item_price,
                                                                                sell_price: this.state.sell_price,
                                                                                quantity:
                                                                                    (this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) + 1
                                                                            })
                                                                        }
                                                                    >
                                                                <i className="fa fa-plus"></i>
                                                          </span>

                                                                </div>
                                                            </button>
                                                        </>
                                                    );
                                                })}
                                        </>
                                    ) : (this.props.val?.item_variables?.length > 1 || !!this.props.val.customizations?.length) ? (
                                        <>
                                            <CartDropDown
                                                         item={this.props.val.item_id}
                                                          variables={this.props.val.item_variables}
                                                          preferences={this.props.val.customizations}
                                                          handleVariables={this.selectHandler}
                                                          isCatering={true}
                                                         productName={this.props.val?.item_name}
                                                         itemId={this.props.val?.item_id}
                                            />
                                        </>
                                    ) : (
                                        <button
                                            className="btn btn-m cart-button menu-toggle"
                                            style={{overflow: "visible"}}
                                            onClick={() => {
                                                if (parseInt(this.props.val.item_variables?.length) === 1) {
                                                    this.addToCart({
                                                        variable_id: this.props.val.item_variables[0]?.id,
                                                        item_price: this.props.val.item_variables[0]?.item_price,
                                                        sell_price: this.props.val.item_variables[0]?.sell_price,
                                                        id: this.props.val.item_id,
                                                        quantity: 1,
                                                    });
                                                }
                                            }}
                                        >
                                            {this.state.quantity_product === this.props.val.item_id && this.state.product_quantity > 0 ?
                                                <i className="fa fa-check"></i>
                                                : "Add to cart"
                                            }
                                        </button>
                                    )}

                                <button className="btn btn-m btn-blank mt-1" onClick={(e) => this.handleClick(e)} aria-describedby={id}>
                                    {this.props.val.item_id === this.state.list_product ? <span className="text-success"> Added </span> :  "Add to list"}
                                </button>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                    className="mt-4"
                                >
                                    <ul>
                                        {this.props.cookies.get("token") ? (
                                            this.state.list.length > 0 ? (
                                                this.state.list.map((val) => {
                                                    return (
                                                        <li
                                                            key={val.id}
                                                            onClick={() => {
                                                                this.props.cookies.set("list_type_id", val.id);
                                                                this.handleList(this.props.val);
                                                            }}
                                                        >
                                                            {val.title}
                                                        </li>
                                                    );
                                                })
                                            ) : <li onClick={() => this.handleList(this.props.val)}>
                                                My List
                                            </li>
                                        ) : (
                                            <li onClick={() => this.handleList(this.props.val)}>
                                                My List
                                            </li>
                                        )}
                                    </ul>
                                </Popover>

                            </div>
                        </div>
                    </div>
                </div>
                <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                      close={() => {
                                          this.setState({showDeliveryOfflineConflictModal: false})
                                      }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
        list: state.ListReducer.userList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) =>
            dispatch(updateCart({type: "UPDATE_CART_ITEMS", data: val})),
        updateListItems: (val) =>
            dispatch(updateList({type: "UPDATE_LIST_ITEMS", data: val})),
    };
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CateCard))));
