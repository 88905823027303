import React from "react";
import {images} from "/src/constant/index";
import {NavLink, withRouter} from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {updateCart, updateList} from "../../actions/cart";
import {limitToLast, onChildAdded, query, ref,} from "@firebase/database";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import API from "../../api";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Popover from "@material-ui/core/Popover";
import LazyLoad from 'react-lazyload';
import DeliveryOfflineModal from "./DeliveryOfflineModal";
import db from "/firebase";
import CartDropDown from "./CartDropDown";

const api = new API();
let timerId;

class FoodCard extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        (this.state = {
            remove_disabled: false,
            removed_item: null,
            show: false,
            cartItems: [],
            toggled: "toggled",
            variable_id: null,
            quantity: 1,
            removed: 0,
            anchorEl: null,
            mode: 0,
            isAdded: 0,
            change: null,
            showDeliveryOfflineConflictModal : false,
            price: null,
            item_price: null,
            selected: null,
            list: [],
            default_store: this.props.cookies.get('selected_store') ? this.props.cookies.get('selected_store') : 2,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            product_quantity: null,
            quantity_product: null,
            offline: false,
            firebase_stock : null,
            list_product: null,
        });
        this.selectHandler = this.selectHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
    }

    handleRedirection = (id) => {
        var products = this.props.cookies.get("recent_view_products") &&
        this.props.cookies.get("recent_view_products").length ? this.props.cookies.get("recent_view_products") : [];
        products.push(id);
        let unique = [...new Set(products)];
        this.props.cookies.set("recent_view_products", unique);
    };

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null},()=>{
            setTimeout(()=>{
                this.setState({list_product : null})
            },1000)
        });
    };

    handleStoreChange() {
        this.setState({show: false}, () => {
            this.props.cookies.set("change_store", false);
            this.props.cookies.set("store_modal", true);
        });
    };

    componentDidMount() {


        if (this.props.cartAddedItems?.cart_items != null) {
            this.setState({
                cartItems: this.props.cartAddedItems?.cart_items ? this.props.cartAddedItems?.cart_items : [],
            });
        }
        if (this.props.cookies.get("token") && this.props.cookies.get("token") !== "" && this.props.list != null) {
            if (this.state.list !== this.props.list?.managelists) {
                this.setState({
                    list: this.props.list?.managelists?.length > 0 ? this.props.list?.managelists : [],
                });
            }
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        //Get firebase real-time stock updates
        this.firebaseLastSnap();
    }

    firebaseLastSnap() {
        const newMessageRef = query(ref(db, `item_stock_logs`),
            limitToLast(1)
        );
        onChildAdded(newMessageRef, (snapshot) => {
            const data = snapshot.val();
            if(data?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) {
                this.setState({firebase_stock: data},()=>{
                    this.props.updateCartItems({
                        cart_id: this.props.cookies.get("cart_id")
                            ? this.props.cookies.get("cart_id")
                            : "blank",
                        token: this.props.cookies.get("token")
                            ? this.props.cookies.get("token")
                            : null,
                    });
                })
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.val?.item_id !== prevProps.val?.item_id) {
            this.setState({ price : null});
        }

        if (this.props.cartAddedItems?.cart_items != null) {
            if (prevProps.cartAddedItems?.cart_items !== this.props.cartAddedItems?.cart_items) {
                const findCart = this.props.cartAddedItems?.cart_items?.find(val => val.item_id === this.props.val?.item_id);
                this.setState({
                    cartItems: this.props.cartAddedItems?.cart_items,
                    product_quantity: findCart ? findCart?.item_count : null,
                    remove_disabled: false,
                    price: findCart ? findCart.variable?.sell_price : null,
                    item_price: null
                });
            }
        }

        if (this.props.cookies.get("token") && this.props.cookies.get("token") !== "" && this.props.list != null) {
            if (prevProps.list?.managelists !== this.props.list?.managelists) {
                this.setState({list: this.props.list?.managelists?.length > 0 ? this.props.list?.managelists : []});
            }
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

    }

    handleList(val) {
        const guestCheckout = this.props.cookies.get("token") ? this.props.cookies.get("token") : null;
        if ( !guestCheckout) {
            this.props.history.push("/login?ref=user/item-list");
            return false;
        }

        const product = {
            product_id: val.item_id,
            variable_id: val.variables[0].id,
            quantity: 1,
            amount: val.variables[0].sell_price,
            customization: '',
            list_unique_id: this.props.cookies.get("list_unique_id") ? this.props.cookies.get("list_unique_id") : "default",
            list_type_id: this.props.cookies.get("list_type_id") ? this.props.cookies.get("list_type_id") : "default",
        };

        api.addToList(
            product,
            this.props.cookies.get("token") ? this.props.cookies.get("token") : null
        )
            .then((success) => {
                if (success.data.status == "400") {
                    this.props.enqueueSnackbar(success.data.message, {
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    });
                } else {
                    this.setState({list_product :  product?.product_id});
                    this.props.cookies.set("list_unique_id", success.data.response.list_unique_id);
                    this.props.cookies.set("list_type_id", success.data.response.list_id);
                    this.handleClose();
                }
            })
            .catch((error) => {
                this.props.enqueueSnackbar(error?.response?.data?.message, {
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                });
            });
    }

    addToCart(val) {

        let time = 250;

        let allowRequest = true;
        if (val.quantity <= 0) {
            this.setState({product_quantity: 0, remove_disabled: true}, () => {
                this.handleRemove(this.state.removed_item);
            });
            allowRequest = false;
        } else {
            this.setState({product_quantity: val.quantity, quantity_product: val.id});
        }

        clearTimeout(timerId);

        //api calling with 250ms delay
        timerId = setTimeout(
            function () {

                if (allowRequest === true) {
                    {
                        parseInt(this.state.default_preference === 1) &&
                        this.setState({
                            show: this.props.cookies.get("change_store") && this.props.cookies.get("change_store") === "false" ? false : true,
                        });
                    }
                    this.setState({price: val.sell_price, item_price: val.item_price});

                    const product = {
                        menu_id: 3,
                        restaurant: this.state.default_store,
                        item_id: val.id,
                        variable_id: val.variable_id,
                        quantity: this.state.product_quantity ? this.state.product_quantity : 1,
                        customization: val.customize === 'No preference' ? '' : (val.customize || '')
                    };

                    api.addToCart(
                        product,
                        this.props.cookies.get("cart_id")
                            ? this.props.cookies.get("cart_id")
                            : "blank",
                        this.props.cookies.get("token") ? this.props.cookies.get("token") : null,
                        this.state.default_preference
                    )
                        .then((success) => {
                            if (success.data.status == "400") {
                                this.setState({errors: {}});
                                this.props.enqueueSnackbar(success.data.message, {
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                });
                            } else if (success.data.status == "402") {
                                confirmAlert({
                                    title: "Other restaurant product already exist",
                                    message: "Are you sure, want to  add this.",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                this.setState({removed: 1}, () => {
                                                    this.addToCart(val);
                                                });
                                            },
                                        },
                                        {
                                            label: "No",
                                        },
                                    ],
                                });
                            } else if (success.data.status == "405") {
                                this.setState({showDeliveryOfflineConflictModal : true})
                            } else {
                                this.props.cookies.set("cart_id", success.data.response);
                                this.setState({removed: 0});
                                this.props.updateCartItems({
                                    cart_id: this.props.cookies.get("cart_id")
                                        ? this.props.cookies.get("cart_id")
                                        : "blank",
                                    token: this.props.cookies.get("token")
                                        ? this.props.cookies.get("token")
                                        : null,
                                });
                            }
                        })
                        .catch((error) => {
                            if (error?.response?.data?.response?.is_grocery_online === 0) {
                                this.setState({offline: true, show: false});
                            } else {
                                this.props.enqueueSnackbar(error?.response?.data?.message, {
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                    },
                                    variant: "error",
                                });
                            }
                        });
                }
            }
                .bind(this),
            time
        );
    }

    WeightPrice(variable_id, item_price, sell_price) {
        this.setState({
            variable_id: variable_id,
            price: sell_price,
            item_price: item_price,
        });
    }

    handleRemove = (item_id) => {
        api.removeFromCart(item_id).then((success) => {
            this.setState({
                product_quantity: null,
            });
            this.props.updateCartItems({
                cart_id: this.props.cookies.get("cart_id")
                    ? this.props.cookies.get("cart_id")
                    : "blank",
                token: this.props.cookies.get("token")
                    ? this.props.cookies.get("token")
                    : null,
            });
        });
    };

    selectHandler(event, customize = '') {
        let item = JSON.parse(event.target.value);
        this.addToCart({
            variable_id: item.id,
            item_price: item.item_price,
            sell_price: item.sell_price,
            id: item.item_id,
            quantity: item.item_count ? item.item_count : 1,
            customize: customize === 'No preference' ? '' : customize,
        });
    }

    render() {

        const open = Boolean(this.state.anchorEl);
        const id = open ? "simple-popover" : undefined;

        const realTimeoutStock = (this.state.firebase_stock && this.state.firebase_stock?.item_id == this.props.val.item_id && (this.state.firebase_stock?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) )
            ? this.state.firebase_stock?.status == 0 : (this.props.val?.restaurant_items?.length > 0 && this.props.val?.restaurant_items?.[0]?.status === 0);

        return (
            <React.Fragment>
                <div
                    className="food-column"
                    data-aos="zoom-in"
                    data-aos-easing="linear"
                >
                    <div className="product-details">

                        {/*{this.props.val.variables[0]*/}
                        {/*    && (this.props.val?.variables[0].item_price > this.props.val?.variables[0].sell_price) && this.props.val?.is_on_sell === 1 ? (*/}
                        {/*        <div className="onsale-details">*/}
                        {/*            <span>On sale</span>*/}
                        {/*        </div>*/}
                        {/*    ) : null }*/}

                        {this.props?.val?.is_on_sell === 1 &&
                        <div className="onsale-details">
                            <span>On sale</span>
                        </div>
                        }

                        <div className="top-img">

                            {this.props?.val?.is_prime === 1 &&
                            <Tippy content="Prime item">
                                <img className="p" src={images.imgP} alt="Not Found"/>
                            </Tippy>
                            }

                            {this.props?.val?.made_locally === 1 &&
                            <Tippy content="Local item">
                                <img className="m" src={images.imgH} alt="Not Found"/>
                            </Tippy>
                            }

                            {this.props?.val?.is_available_in_bulk === 1 &&
                            <Tippy content="Bulk item">
                                <img className="b" src={images.imgB} alt="Not Found"/>
                            </Tippy>
                            }
                        </div>

                        {/*<NavLink exact to={`/shop/${this.props.val.slug}`}>
                            <a onClick={() => {
                                this.handleRedirection(this.props.val.item_id);
                            }}>
                                <div className="food-img">
                                    <LazyLoad>
                                        <img src={`https://image.qualwebs.com/211x211/${(this.props.val.images.length > 0
                                            ? this.props.val.images[0].path
                                            : images.food)}`}
                                             className="img-fluid"
                                             alt={this.props.val?.item_name}
                                        />
                                    </LazyLoad>
                                </div>
                                <div className="food-content">
                                    <h6> {this.props.val?.item_name}</h6>
                                    <p className="price">
                                        <sup></sup>
                                        <span>$
                                            {this.state.price
                                                ? Number(this.state.price).toFixed(2)
                                                : this.props.val.variables[0]
                                                    ? Number(this.props.val?.variables[0].sell_price).toFixed(2)
                                                    : ""}{" "}</span>
                                        each
                                        {this.props.val.variables[0]
                                        && (this.props.val.variables[0].item_price > this.props.val.variables[0].sell_price)
                                            ?
                                            <del>
                                                ${this.state.item_price
                                                ? Number(this.state.item_price).toFixed(2)
                                                : this.props.val.variables[0]
                                                    ? Number(this.props.val?.variables[0].item_price).toFixed(2)
                                                    : ""}
                                            </del>
                                            : ""}
                                    </p>
                                </div>
                            </a>
                        </NavLink>*/}

                        <NavLink exact to={`/shop/${this.props.val.slug}`}>
                            <div className="food-img" onClick={() => {
                                this.handleRedirection(this.props.val.item_id);
                            }}>
                                <LazyLoad key={this.props.val.item_id} once>
                                    <img
                                        src={this.props.val.images.length > 0 ? `https://image.qualwebs.com/211x211/${this.props.val.images[0].path}` : images.food}
                                        className="img-fluid"
                                        alt={this.props.val?.item_name}
                                    />
                                </LazyLoad>
                            </div>
                        </NavLink>

                        <div className="food-content">
                            <NavLink className="ad-fc" exact to={`/shop/${this.props.val.slug}`}>
                                <h6 onClick={() => {
                                    this.handleRedirection(this.props.val.item_id);
                                }}>
                                    {this.props.val?.item_name}
                                </h6>
                            </NavLink>
                            <NavLink className="ad-fc" exact to={`/shop/${this.props.val.slug}`}>
                                <p className="price">
                                    {/*<del>$25.20</del>*/}
                                    {this.props.val.variables[0] && (Number(this.props.val.variables[0].item_price) > Number(this.props.val.variables[0].sell_price))
                                        ?
                                        <>
                                            <del>
                                                ${this.state.item_price
                                                ? Number(this.state.item_price).toFixed(2)
                                                : this.props.val.variables[0]
                                                    ? Number(this.props.val?.variables[0].item_price).toFixed(2)
                                                    : ""}
                                            </del>
                                            <sup></sup>
                                            <span className="on-sale">$
                                                {this.state.price
                                                    ? Number(this.state.price).toFixed(2)
                                                    : this.props.val.variables[0]
                                                        ? Number(this.props.val?.variables[0].sell_price).toFixed(2)
                                                        : ""}{" "}
                                             </span>
                                        </>
                                        :
                                        <>
                                            <sup></sup>
                                            <span>
                                                ${this.state.price
                                                ? Number(this.state.price).toFixed(2)
                                                : this.props.val.variables[0]
                                                    ? Number(this.props.val?.variables[0].sell_price).toFixed(2)
                                                    : ""}{" "}
                                             </span>
                                        </>
                                    }
                                </p>
                            </NavLink>
                        </div>


                        {realTimeoutStock ?
                            <div className="buttons mt-1">
                                <span className="deliverable d-inline-block w-100">
                                    <p className="">Out-Of-Stock</p>
                                </span>
                            </div>
                            :
                            (parseInt(this.state.default_preference) === 2 && this.props.val?.is_deliverable === 0) ?
                                <div className="buttons">
                                    <span className="deliverable d-inline-block w-100">
                                        <p className="">In-Store Pickup Only</p>
                                    </span>
                                </div>
                                :
                                <>
                                    <div className="buttons mt-1">
                                        {this.state.cartItems.find(
                                            (item) => item.item_id === this.props.val.item_id
                                        ) ? (
                                            <>
                                                {this.state.cartItems
                                                    .filter((item) => item.item_id === this.props.val.item_id)
                                                    .slice(0,1)
                                                    .map((val, index) => {
                                                        const allCartItems = this.state.cartItems.filter((item) => item.item_id === this.props.val.item_id);
                                                        const totalItems = allCartItems.length;
                                                        let found = this.props.val.variables.find(o => parseInt(val.variable_id) === parseInt(o.id));
                                                        return (this.props.val.variables?.length > 1 || !!this.props.val.customizations?.length) ? (
                                                            <>
                                                                <CartDropDown
                                                                              item={this.props.val.item_id}
                                                                              variables={this.props.val?.variables || []}
                                                                              preferences={this.props.val?.customizations || []}
                                                                              handleVariables={this.selectHandler}
                                                                              btnLabel={found ? `${totalItems} added` : "Add to cart"}
                                                                              cartValue={val}
                                                                              isAddedToCart={true}
                                                                              productName={this.props.val?.item_name}
                                                                              itemId={this.props.val?.item_id}
                                                                />
                                                                <div className="added" key={val.item_id}>
                                                            <span
                                                                className="minus"
                                                                onClick={() => {
                                                                    if(this.props.val.variables?.length > 1 || !!this.props.val.customizations?.length) {
                                                                        const ele = document.getElementById('dropdownButton' + this.props.val?.item_id);
                                                                        if(!!ele) {
                                                                            ele.click();
                                                                        }
                                                                    } else {
                                                                        this.handleRemove(val.cart_item_id);
                                                                    }

                                                                }}
                                                            >
                                                                {(this.props.val.variables?.length > 1 || !!this.props.val.customizations?.length)
                                                                    ?
                                                                    <i className="fa fa-trash"></i>
                                                                    :
                                                                    ((this.state.quantity_product === val.item_id &&
                                                                        this.state.product_quantity !== null &&
                                                                        this.state.product_quantity) ?
                                                                        this.state.product_quantity : val.item_count) === 1
                                                                        ? <i className="fa fa-trash"></i> :
                                                                        <i className="fa fa-minus"></i>
                                                                }
                                                            </span>
                                                                </div>
                                                            </>
                                                        ) : (

                                                            <>
                                                                <p className="d-none">{found ? (found?.unit?.name ?? "") === "pc" ? (parseInt(found?.weight) +" "+ (found?.unit?.name ?? "")) : (found?.weight +" "+ (found?.unit?.name ?? ""))  : "Add to cart"}</p>

                                                                <button className="btn btn-m cart-button menu-toggle"
                                                                        style={{overflow: "visible"}}>
                                                                    <div className="added" key={val.item_id}>
                                                                <span
                                                                    className={(this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ? "minus disabled" : "minus"}
                                                                    onClick={() => this.setState({removed_item: val.cart_item_id}, () => {

                                                                        if (((this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1) < 0) {
                                                                            this.setState({remove_disabled: true}, () => {
                                                                                this.handleRemove(val.cart_item_id);
                                                                            });
                                                                        } else {
                                                                            this.addToCart({
                                                                                id: val.item_id,
                                                                                variable_id: val.variable_id,
                                                                                item_price: this.state.item_price,
                                                                                sell_price: this.state.sell_price,
                                                                                quantity: ((this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1),
                                                                            })
                                                                        }
                                                                    })
                                                                    }
                                                                >
                                                                {
                                                                    (this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) === 1 ?
                                                                        <i className="fa fa-trash"></i> :
                                                                        <i className="fa fa-minus"></i>
                                                                }
                                                              </span>
                                                                        <span>
                                                                      {
                                                                          (this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ?
                                                                              <i className="fa fa-times"></i> :
                                                                              (this.state.product_quantity ? this.state.product_quantity : val.item_count) + " added"
                                                                      }
                                                              </span>


                                                                        <span
                                                                            className={(this.state.quantity_product === val.item_id && this.state.remove_disabled === true) ? "plus disabled" : "plus"}
                                                                            onClick={() =>
                                                                                this.addToCart({
                                                                                    id: val.item_id,
                                                                                    variable_id: val.variable_id,
                                                                                    item_price: this.state.item_price,
                                                                                    sell_price: this.state.sell_price,
                                                                                    quantity:
                                                                                        (this.state.quantity_product === val.item_id && this.state.product_quantity ? this.state.product_quantity : val.item_count) + 1
                                                                                })
                                                                            }
                                                                        >
                                                                    <i className="fa fa-plus"></i>
                                                              </span>

                                                                    </div>
                                                                </button>
                                                            </>
                                                        );
                                                    })}
                                            </>
                                        ) : (this.props.val?.variables?.length > 1 || !!this.props.val?.customizations?.length) ? (
                                            <>
                                                <CartDropDown
                                                              item={this.props.val.item_id}
                                                              variables={this.props.val.variables || []}
                                                              preferences={this.props.val?.customizations || []}
                                                              handleVariables={this.selectHandler}
                                                              productName={this.props.val?.item_name}
                                                              itemId={this.props.val?.item_id}
                                                />
                                            </>
                                        ) : (
                                            <button
                                                className="btn btn-m cart-button menu-toggle"
                                                style={{overflow: "visible"}}
                                                onClick={() => {
                                                    if (parseInt(this.props.val.variables.length) === 1) {
                                                        this.addToCart({
                                                            variable_id: this.props.val.variables[0].id,
                                                            item_price: this.props.val.variables[0].item_price,
                                                            sell_price: this.props.val.variables[0].sell_price,
                                                            id: this.props.val.item_id,
                                                            quantity: 1,
                                                        });
                                                    }
                                                }}
                                            >
                                                {this.state.quantity_product === this.props.val.item_id && this.state.product_quantity > 0 ?
                                                    <i className="fa fa-check"></i> : "Add to cart"
                                                }
                                            </button>
                                        )}
                                    </div>
                                </>
                        }

                        <button className="btn btn-m btn-blank" onClick={(e) => this.handleClick(e)} aria-describedby={id}>
                            {this.props.val.item_id === this.state.list_product ? <span className="text-success"> Added </span> :  "Add to list"}
                        </button>

                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            className="mt-4"
                        >
                            <ul>
                                {this.props.cookies.get("token") ? (
                                    this.state.list.length > 0 ? (
                                        this.state.list.map((val) => {
                                            return (
                                                <li
                                                    key={val.id}
                                                    onClick={() => {
                                                        this.props.cookies.set("list_type_id", val.id);
                                                        this.handleList(this.props.val);
                                                    }}
                                                >
                                                    {val.title}
                                                </li>
                                            );
                                        })
                                    ) : <li onClick={() => this.handleList(this.props.val)}>
                                        My List
                                    </li>
                                ) : (
                                    <li onClick={() => this.handleList(this.props.val)}>
                                        My List
                                    </li>
                                )}
                            </ul>
                        </Popover>

                    </div>
                </div>
                <Modal
                    dialogClassName="modal-dialog-centered"
                    onHide={() => {
                        this.setState({show: false}, () => {
                            this.props.cookies.set("change_store", false);
                        });
                    }} show={this.state.show} className="storeModal">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 p-0">
                                <h2 className="card-heading w-100 mt-1 mb-3">
                                    Let’s confirm your Farmer’s location.
                                </h2>
                                <h6 className="w-100 mb-0 card-text">
                                    You’re currently shopping
                                    from <b>{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"}.</b> Is
                                    this
                                    the location ?
                                </h6>
                            </div>
                            <hr/>
                            <div className="text-center">
                                <button
                                    className="btn btn-s proceed-button mt-1"
                                    onClick={() => {
                                        this.setState({show: false}, () => {
                                            this.props.cookies.set("change_store", false);
                                        });
                                    }}
                                >
                                    Continue shopping
                                    at <br/> {parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"}
                                </button>
                                <small className="my-2 d-inline-block w-100">OR</small>
                                <button
                                    className="btn btn-s cart-button"
                                    onClick={() => this.handleStoreChange()}
                                >
                                    Change location
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                      close={() => {
                                          this.setState({showDeliveryOfflineConflictModal: false})
                                      }}
                />

                <Modal dialogClassName="modal-dialog-centered" onHide={() => {
                    this.setState({
                        offline: false
                    })
                }} show={this.state.offline} className="storeModal">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 text-center p-0">
                                    <span className="">
                                        <img key={this.props.val.item_id} width="200px" src={images.offline}/>
                                    </span>
                                <br/>
                                <h1 className="card-text w-100 font-weight-bold mt-3">{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"} </h1>
                            </div>
                            <hr/>
                            <div className="text-center">
                                <button onClick={() => {
                                    this.setState({quantity_product: null, product_quantity: 0, offline: false}, () => {
                                        this.props.cookies.set('selected_store', (parseInt(this.state.default_store) === 2 ? 1 : 2));
                                    })
                                }} className="btn btn-s proceed-button mb-3 mb-md-0"
                                        style={{
                                            transition: 'all .3s ease-in-out'
                                        }}>
                                    {parseInt(this.state.default_store) === 2 ? "Continue, with Farmer’s Fresh Meat at Cullen" : "Continue, with Farmer’s Fresh Meat at Mesa"}
                                </button>
                                <button onClick={() => this.setState({
                                    quantity_product: null,
                                    product_quantity: 0,
                                    offline: false
                                })}
                                        className="btn btn-s cart-button">Cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
        list: state.ListReducer.userList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) =>
            dispatch(updateCart({type: "UPDATE_CART_ITEMS", data: val})),
        updateListItems: (val) =>
            dispatch(updateList({type: "UPDATE_LIST_ITEMS", data: val})),
    };
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(FoodCard))));
