import React from 'react';
import {images} from '../../constant';
import API from "../../api";
import {updateCart} from "../../actions/cart";
import {Cookies, withCookies} from "react-cookie";
import {withSnackbar} from "notistack";
import {connect} from "react-redux";
import {instanceOf} from "prop-types";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {withRouter} from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import {Modal} from "react-bootstrap";
import Related from "../Home/children/related";
import {Helmet} from "react-helmet";
import RecentViewed from "../Home/children/recent";
import "react-image-gallery/styles/css/image-gallery.css";
import "lightgallery.js/dist/css/lightgallery.css";
import ImageGallery from "react-image-gallery";
import Button from "react-bootstrap/Button";
import {SideBySideMagnifier} from "react-image-magnifiers";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import NotFound from "../Static/NotFound";
import DeliveryOfflineModal from "./DeliveryOfflineModal"; // This only needs to be imported once in your app
import cookie from "cookie";
import {limitToLast, onChildAdded, query, ref} from "@firebase/database";
import db from "../../../firebase";
import CartDropDown from "./CartDropDown";

let timerId;
const api = new API();

class SingleProduct extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            remove_disabled: false,
            removed_item: null,
            show: false,
            removed: 0,
            variables: [],
            images: [],
            tags: [],
            category: [],
            singleProduct: {},
            cartItems: [],
            related_products: [],
            isAdded: 0,
            sell_price: null,
            item_price: null,
            variable_id: null,
            product_id: null,
            showDeliveryOfflineConflictModal: false,
            peralink: null,
            variable: {},
            list: [],
            anchorEl: null,
            nutrition: {
                serving: [],
                calories: [],
                daily_values: []
            },
            default_store: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            product_quantity: null,
            confirm_restaurant: false,
            image_index: 0,
            offline: false,
            price: null,
            weight: null,
            unit : null,
            firebase_stock : null
        };
        this.selectHandler = this.selectHandler.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.handleImageClick = this.handleImageClick.bind(this);
    }

    handleRedirection(path) {
        this.props.history.push(path);
    }

    handleStoreChange() {
        this.setState({confirm_restaurant: false}, () => {
            this.props.cookies.set("change_store", false);
            this.props.cookies.set("store_modal", true);
        });
    };

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    static async getInitialProps(ctx) {

        const { req , match } = ctx;

        let selectedStore = null;
        if (req && req.headers && req.headers.cookie) {
            // On the server-side, you can access cookies using headers.cookie
            const cookies = cookie.parse(req.headers.cookie);
            selectedStore = cookies?.selected_store || 2; //default Mesac

        } else if (typeof window !== 'undefined') {
            // On the client-side, you can access cookies using document.cookie
            const cookies = cookie.parse(document.cookie);
            selectedStore = cookies?.selected_store || 2; //default Mesa
        }

        let slug = match.params.product_id;
        const products = await api.getSingleProduct(slug,selectedStore);
        if (products.data.response !== null) {
            const categories = [];
            if (products.data.response.categories && products.data.response.categories.length > 0) {
                products.data.response.categories.length > 0 ? products.data.response.categories.map((val, index) => {
                    categories.push(val?.category_id);
                }) : "";
            }

            return {
                found: 1,
                slug: slug,
                meta_title: products.data.response.meta_title ? products.data.response.meta_title : "Farmer's Fresh Meat: Houston Meat Market & Butcher Shop",
                meta_description: products.data.response.meta_description,
                sell_price: products.data.response.variables[0].sell_price ? products.data.response.variables[0].sell_price : null,
                item_price: products.data.response.variables[0].item_price ? products.data.response.variables[0].item_price : null,
                unit: products.data.response.variables[0].unit?.name ? products.data.response.variables[0].unit?.name : null,
                weight: products.data.response.variables[0].weight ? products.data.response.variables[0].weight : null,
                variable_id: products.data.response.variables[0].id ? products.data.response.variables[0].id : null,
                variables: products.data.response.variables,
                tags: products.data.response?.tags,
                customizations: products.data.response?.customizations || [],
                restaurant_items : products.data.response.restaurant_items,
                category: products.data.response.categories,
                images: products.data.response.images.length ? products.data.response.images.map((image) => {
                    return ({original: `${image.path}`, thumbnail: `${ "https://image.qualwebs.com/200x200/" + image.path}`})
                }) : [{original: images.food, thumbnail: images.food}],
                mobile_images: products.data.response.images.length ? products.data.response.images.map((image) => {
                    return image.path;
                }) : [images.food],
                singleProduct: products.data.response,
                variable: products.data.response.variables[0],
                peralink: "https://farmersfreshmeat.com/shop/" + slug,
                categories: categories,
                selectedStore : selectedStore
            }
        } else {
            return {
                found: 0
            }
        }
    }

    getCategoryProducts() {
        api.getCategoryProducts({
            categories: this.props.categories,
            product_id: this.props?.singleProduct?.item_id,
            restaurant_id: this.state.default_store
        }).then(products => {
            this.setState({
                product_id: this.props?.singleProduct?.item_id,
                related_products: products.data.response
            })
        });
    }

    handleImageClick() {
        this.setState({isOpen: true});
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.getCategoryProducts();

        console.log(this.props)

        if (this.props.cookies.get('token') && this.props.cookies.get('token') !== "" && this.props.list.length > 1) {
            if (this.state.list !== this.props.list) {
                this.setState({list: this.props.list.length > 0 ? this.props.list[0].userList : []});
            }
        }
        if (Object.keys(this.props.cartAddedItems).length !== 0 && this.props.cartAddedItems?.cart_items?.length !== this.state.cartItems?.length) {
            this.setState({cartItems: this.props?.cartAddedItems?.cart_items});
        }
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }
        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        this.firebaseLastSnap();
    }

    componentDidUpdate(nextProps, prevProps) {

        if (this.state.product_id !== null && prevProps.product_id !== this.props?.singleProduct?.item_id && this.props?.singleProduct?.item_id !== this.state.product_id) {
            this.getCategoryProducts();
        }
        if (prevProps.list !== this.props.list && this.props.list.length > 1 && this.state.list !== this.props.list) {
            this.setState({list: this.props.list.length > 0 ? this.props.list[0].userList : []});
        }
        if (Object.keys(this.props.cartAddedItems).length !== 0 && this.props.cartAddedItems?.cart_items && this.props.cartAddedItems?.cart_items !== prevProps?.cart_items
            && this.props.cartAddedItems?.cart_items !== this.state.cartItems) {
            if (this.state.remove_disabled === true && this.state.product_quantity === null) {
                this.setState({remove_disabled: false})
            }
            const findCart = this.props.cartAddedItems?.cart_items?.filter(val => val.item_id === this.props?.singleProduct?.item_id);
            const totalAmount = !!findCart.length ? findCart.reduce((total, a) => Number(a.total_amount) + Number(total), 0) : Number(this.props.item_price).toFixed(2) ;

            this.setState({
                cartItems: this.props.cartAddedItems?.cart_items,
                price: !!findCart.length ? Number(totalAmount).toFixed(2) : null,
                weight : null,
                unit : null
            });
        }
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }
        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }
    }

    firebaseLastSnap() {
        const newMessageRef = query(ref(db, `item_stock_logs`),
            limitToLast(1)
        );
        onChildAdded(newMessageRef, (snapshot) => {
            const data = snapshot.val();
            if(data?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) {
                this.setState({firebase_stock: data})
            }
        });
    }

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    selectHandler(event, customize = '') {
        let item = JSON.parse(event.target.value);
        this.addToCart({
            'variable_id': item.id,
            'item_price': item.item_price,
            'sell_price': item.sell_price,
            'id': item.item_id,
            'quantity': item.item_count ? item.item_count : 1,
            'unit': (item?.unit?.name ?? ""),
            'weight': item?.weight,
            customize: customize === 'No preference' ? '' : customize,
        })
    }

    handleRemove(item_id) {
        api.removeFromCart(item_id).then(success => {
            this.setState({
                product_quantity: null,
                unit : null,
                price : null,
                weight : null
            });
            this.props.updateCartItems({
                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null,
            });
        });
    };

    addToCart(val) {

        var time = 300;

        var allowRequest = true;
        if (val.quantity <= 0) {
            this.setState({product_quantity: null, remove_disabled: true}, () => {
                this.handleRemove(this.state.removed_item);
            });
            allowRequest = false;
        } else {
            this.setState({product_quantity: val.quantity});
        }

        clearTimeout(timerId);

        //api calling with 500ms delay
        timerId = setTimeout(
            function () {

                if (allowRequest === true && this.state.product_quantity !== null) {
                    {
                        parseInt(this.state.default_preference) === 1 &&
                        this.setState({
                            confirm_restaurant: this.props.cookies.get("change_store") &&
                            this.props.cookies.get("change_store") === "false"
                                ? false
                                : true,
                        });
                    }

                    this.setState({price: val.sell_price, sell_price: val.sell_price, item_price: val.item_price,unit : val?.unit,weight : val?.weight});

                    const product = {
                        'menu_id': 3,
                        'restaurant': this.state.default_store,
                        "item_id": val.id,
                        "variable_id": val.variable_id,
                        "quantity": this.state.product_quantity,
                        customization: val.customize === 'No preference' ? '' : (val.customize || ''),
                        // "removed": this.state.removed,
                        // "change": 0
                    };

                    api.addToCart(
                        product,
                        this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                        this.props.cookies.get('token') ? this.props.cookies.get('token') : null,
                        this.state.default_preference).then(success => {

                        if (success.data.status == "400") {
                            this.setState({errors: {}});
                            this.props.enqueueSnackbar(success.data.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                variant: 'error',
                            });
                        } else if (success.data.status == "402") {
                            confirmAlert({
                                title: 'Other restaurant product already exist',
                                message: 'Are you sure, want to  add this.',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => {
                                            this.setState({removed: 1}, () => {
                                                this.addToCart(val)
                                            });
                                        }
                                    },
                                    {
                                        label: 'No',
                                    }
                                ]
                            });
                        } else if (success.data.status == "405") {
                            this.setState({showDeliveryOfflineConflictModal: true})
                        } else {

                            this.props.cookies.set('cart_id', success.data.response);
                            this.setState({removed: 0});
                            this.props.updateCartItems({
                                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null
                            });
                        }
                    }).catch(error => {
                        if (error?.response?.data?.response?.is_grocery_online === 0) {
                            this.setState({offline: true});
                        } else {
                            this.props.enqueueSnackbar(error?.response?.data?.message, {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "error",
                            });
                        }
                    });
                }
            }
                .bind(this),
            time
        );
    }

    WeightPrice(price, item_price, variable_id) {
        this.setState({sell_price: price, item_price: item_price, variable_id: variable_id});
    }

    handleList(val) {

        const product = {
            "product_id": val.item_id,
            "variable_id": val.variables[0].id,
            "quantity": 1,
            customization: '',
            "amount": val.variables[0].sell_price,
            list_unique_id: this.props.cookies.get("list_unique_id") ? this.props.cookies.get("list_unique_id") : "default",
            list_type_id: this.props.cookies.get("list_type_id") ? this.props.cookies.get("list_type_id") : "default",
        };

        api.addToList(product, this.props.cookies.get('token') ? this.props.cookies.get('token') : null).then(success => {

            if (success.data.status == "400") {
                this.props.enqueueSnackbar(success.data.message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    variant: 'error',
                });
            } else {
                this.props.cookies.set('list_unique_id', success.data.response.list_unique_id);
                this.props.cookies.set('list_type_id', success.data.response.list_id);
                this.props.enqueueSnackbar("product added to list", {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    variant: 'success',
                });
                this.handleClose();
            }
        }).catch(error => {
            this.props.enqueueSnackbar(error?.response?.data?.message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                variant: 'error',
            });
        });
    }

    render() {

        const ogImage = this.props?.singleProduct?.images.length > 0 ? this.props?.singleProduct?.images[0].path : "https://admin.farmersfreshkitchen.com/public/storage/images/common/1693067090download.png";
        const delivery_preference = (parseInt(this.state.default_preference) === 2 && parseInt(this.props.singleProduct?.is_deliverable) === 1) || (parseInt(this.state.default_preference) === 1);
        const non_deliverable = parseInt(this.state.default_preference) === 2 && this.props?.singleProduct?.is_deliverable === 0;

        const realTimeoutStock =  (this.state.firebase_stock &&  this.state.firebase_stock?.item_id == this.props?.singleProduct.item_id && (this.state.firebase_stock?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ))
            ?  this.state.firebase_stock?.status == 0 : (this.props.restaurant_items?.length > 0 && this.props?.restaurant_items?.[0]?.status === 0);

        var mobileView = false;
        {
            if (typeof window !== "undefined") {
                if (window.innerWidth < 768) {
                    mobileView = true;
                }
            }
        }
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const allCartItems = !!this.state.cartItems?.length ? this.state.cartItems?.filter((item) => item.item_id === this.props?.singleProduct?.item_id) : [];
        const isMultipleVariablesAdded = allCartItems.length > 1;
        const cartVariable = this.state.cartItems.find((item) => item.item_id === this.props?.singleProduct.item_id);
        const variableWeight = cartVariable?.variable && cartVariable?.variable?.weight ? cartVariable?.variable?.weight : this.props.variable?.weight;
        const variableUnit = cartVariable?.variable && cartVariable?.variable?.unit ? cartVariable?.variable?.unit?.name : this.props.variable?.unit?.name;
        const variableAmount = cartVariable?.variable && cartVariable?.variable?.sell_price ? cartVariable?.variable?.sell_price : this.props.variable?.sell_price;
        const totalAmount = !!allCartItems.length ? allCartItems.reduce((total, a) => Number(a.total_amount) + Number(total), 0) : variableAmount ;
        return (
            <React.Fragment>
                {this.props?.found === 1 ?
                    <>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>{this.props?.meta_title}</title>
                            <meta name="description" content={this.props?.meta_description}/>
                            <meta name="robots" content="index, follow"/>
                            <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
                            <meta property="og:type" content="article"/>
                            <meta property="og:title" content={this.props?.meta_title}/>
                            <meta property="og:description" content={this.props?.meta_description}/>
                            <meta property="og:url" content={this.props?.peralink}/>
                            <meta property="og:site_name" content="Farmer&#039;s Fresh Meat: Houston Meat Market &amp; Butcher Shop"/>
                            <meta property="article:publisher" content="https://www.facebook.com/farmersfreshmeat"/>
                            <meta property="article:modified_time" content="2022-02-28T13:10:39+00:00"/>
                            <meta property="og:image" content={ogImage}/>
                            <meta property="og:image:width" content="1449"/>
                            <meta property="og:image:height" content="1024"/>
                            <meta name="twitter:card" content="summary"/>
                            <meta name="twitter:creator" content="Farmerfreshmeat"/>
                            <meta name="twitter:site" content="Farmerfreshmeat"/>
                            <link rel="canonical" href={this.props?.peralink}/>
                            <script type="application/ld+json" >
                                {`
                                  {
                                    "@context": "https://schema.org",
                                    "@type": "Product",
                                    "description": "${this.props?.singleProduct?.item_description}",
                                    "name": "${this.props?.singleProduct?.item_name}",
                                    "image": "${this.props?.images.length > 0 ? this.props.images[0].original : ""}",
                                    "offers": {
                                      "@type": "Offer",
                                      "availability": "${realTimeoutStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock'}",
                                      "price": "${this.props?.sell_price}",
                                      "priceCurrency": "USD"
                                    }
                                  }
                                `}
                            </script>
                        </Helmet>

                        <div className="container container-margin px-0 px-sm-3 mb-fix">
                            <div className="col-12">
                                <>
                                    <div className="row">
                                        <div className="col-md-12 mt-0">
                                            <div className="breadcrumb-details">
                                                <nav aria-label="breadcrumb">
                                                    <ol className="breadcrumb pb-1 pt-0">
                                                        <li className="breadcrumb-item "
                                                            onClick={() => this.handleRedirection('/')}><a href="#">Farmer's
                                                            Fresh Meat</a></li>
                                                        <li className="breadcrumb-item"
                                                            onClick={() => this.handleRedirection('/search/all')}><a
                                                            href="#">Shop</a></li>
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            <a>{this.props?.singleProduct ? this.props.singleProduct.item_name : ""}</a>
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-9 mb-m">
                                            {/*************1*************/}
                                            <div className="bg-white mt-0 p-0 single-product main-section mb-0">
                                                <div
                                                    className="product-details sale-banner"
                                                    key={this.props?.singleProduct.item_id}
                                                >
                                                    {/*{this.props?.sell_price && this.props?.item_price && (this.props.item_price > this.props.sell_price) && this.props.singleProduct?.is_on_sell === "1" ? (*/}
                                                    {/*    <div className="onsale-details">*/}
                                                    {/*        <span>On sale</span>*/}
                                                    {/*    </div>*/}
                                                    {/*) : null}*/}

                                                    {this.props?.singleProduct?.is_on_sell === 1 &&
                                                    <div className="onsale-details">
                                                        <span>On sale</span>
                                                    </div>
                                                    }
                                                </div>
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div
                                                            className="col-12 mx-auto mx-md-0 col-lg-6 order-1 order-lg-0">
                                                            <div
                                                                id="myCarousel"
                                                                className="carousel slide"
                                                            >
                                                                {/*<!-- main slider carousel items -->*/}
                                                                <div>
                                                                    {this.props?.images.length > 1 ?
                                                                        <>
                                                                            <div
                                                                                className="col-12 d-none d-md-block p-0">
                                                                                <SideBySideMagnifier
                                                                                    className={"img-overlay custom-cursor"}
                                                                                    imageSrc={
                                                                                        "https://image.qualwebs.com/900x900/" + this.props?.images[this.state.image_index]?.original
                                                                                    }
                                                                                    imageAlt={this.props.singleProduct?.item_name}
                                                                                    largeImageSrc={
                                                                                        "https://image.qualwebs.com/900x900/" + this.props?.images[this.state.image_index]?.original
                                                                                    }
                                                                                    zoomContainerBorder="1px solid #ccc"
                                                                                    zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                                                    fillAvailableSpace={true}
                                                                                    fillAlignTop={0}
                                                                                    fillGapTop={130}
                                                                                    fillGapBottom={50}
                                                                                    fillGapRight={100}
                                                                                    fillGapLeft={25}
                                                                                    overlayOpacity={0.2}
                                                                                />
                                                                            </div>

                                                                            <div
                                                                                className="col-12 d-block d-md-none p-0">
                                                                                <div className="col-12 p-0"
                                                                                     onClick={(e) => {
                                                                                         this.handleImageClick(e)
                                                                                     }}>
                                                                                    <img className="img-fluid"
                                                                                         src={this.props?.images[this.state.image_index]?.original}/>
                                                                                </div>
                                                                            </div>

                                                                            <ImageGallery infinite={true}
                                                                                          showFullscreenButton={false}
                                                                                          disableKeyDown={true}
                                                                                          showNav={false}
                                                                                          showPlayButton={false}
                                                                                          slideOnThumbnailOver={true}
                                                                                          onSlide={(event) => {
                                                                                              this.setState({image_index: event})
                                                                                          }}
                                                                                          items={this.props?.images}/>

                                                                        </>

                                                                        :

                                                                        this.props?.images.map((item, index) => (
                                                                            {
                                                                                ...mobileView ?

                                                                                    <>
                                                                                        <div
                                                                                            className="col-12 d-block d-md-none p-0">
                                                                                            <div className="col-12 p-0"
                                                                                                 onClick={(e) => {
                                                                                                     this.handleImageClick(e)
                                                                                                 }}>
                                                                                                <img
                                                                                                    className="img-fluid"
                                                                                                    src={item?.original}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>

                                                                                    :
                                                                                    <SideBySideMagnifier
                                                                                        imageSrc={
                                                                                            item.original
                                                                                        }
                                                                                        imageAlt={this.props.singleProduct?.item_name}
                                                                                        largeImageSrc={
                                                                                            "https://image.qualwebs.com/900x900/" + item.original
                                                                                        }
                                                                                        zoomContainerBorder="1px solid #ccc"
                                                                                        zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                                                        fillAvailableSpace={true}
                                                                                        fillAlignTop={0}
                                                                                        fillGapTop={130}
                                                                                        fillGapBottom={50}
                                                                                        fillGapRight={100}
                                                                                        fillGapLeft={25}
                                                                                        overlayOpacity={0.2}
                                                                                    />
                                                                            }
                                                                        ))
                                                                    }

                                                                    {this.state.isOpen &&
                                                                    <Lightbox
                                                                        mainSrc={this.props?.mobile_images[this.state.image_index]}
                                                                        nextSrc={this.props?.mobile_images[(this.state.image_index + 1) % this.props?.mobile_images.length]}
                                                                        prevSrc={this.props?.mobile_images[(this.state.image_index + this.props?.mobile_images.length - 1) % this.props?.mobile_images.length]}
                                                                        onCloseRequest={() => this.setState({
                                                                            isOpen: false,
                                                                            image_index: 0
                                                                        })}
                                                                        onMovePrevRequest={() =>
                                                                            this.setState({
                                                                                image_index: (this.state.image_index + this.props?.mobile_images.length - 1) % this.props?.mobile_images.length,
                                                                            })
                                                                        }
                                                                        onMoveNextRequest={() =>
                                                                            this.setState({
                                                                                image_index: (this.state.image_index + 1) % this.props?.mobile_images.length,
                                                                            })
                                                                        }
                                                                    />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-12 d-block d-lg-none single-pro-right pt-0 order-2 pb-2">

                                                            <p className="price mb-0">
                                                                <span>
                                                                      {this.props.sell_price && this.props.item_price && (Number(this.props.item_price) - Number(this.props.sell_price)) > 0 ?
                                                                          <>
                                                                              <sup className="">
                                                                                  <del>${this.props.item_price ? Number(this.props.item_price).toFixed(2) : null}</del>
                                                                              </sup>
                                                                              <span className="on-sale d-flex align-items-center justify-content-center">
                                                                                ${this.props.sell_price ? Number(this.props.sell_price).toFixed(2) : null}
                                                                                  {/\d/.test(this.state.weight ? this.state.weight : variableWeight) &&
                                                                                     <h6 className="mb-0 pl-1 pt-1 font-10 text-muted">(${this.state.price ? Number(this.state.price / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : variableAmount ? Number(variableAmount / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : null}{" ⁄ " + (this.state.unit ? this.state.unit : variableUnit)})</h6>
                                                                                  }
                                                                              </span>
                                                                          </>
                                                                          :
                                                                          <span className="d-flex align-items-center justify-content-center">
                                                                              ${isMultipleVariablesAdded ? Number(totalAmount).toFixed(2) : this.state.price ? Number(this.state.price).toFixed(2) : variableAmount ? Number(variableAmount).toFixed(2) : null}
                                                                              {!isMultipleVariablesAdded &&
                                                                              <>{/\d/.test(this.state.weight ? this.state.weight : variableWeight) &&
                                                                                <h6 className="mb-0 pl-1 pt-1 font-10 text-muted">(${this.state.price ? Number(this.state.price / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : variableAmount ? Number(variableAmount / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : null}{" ⁄ " + (this.state.unit ? this.state.unit : variableUnit)})</h6>
                                                                              }</>
                                                                              }
                                                                          </span>
                                                                      }
                                                                </span>
                                                            </p>


                                                            {this.props.sell_price && this.props.item_price && (Number(this.props.item_price) - Number(this.props.sell_price)) > 0 ?
                                                                <div
                                                                    className="col-12 food-content d-inline-block px-0 pb-1">
                                                                    <p className="price">
                                                                        <p className="text-success">You save:
                                                                            ${this.props.sell_price ? Number(this.props.item_price - this.props.sell_price).toFixed(2) : null}</p>
                                                                    </p>
                                                                </div>
                                                                : ""}

                                                            {/*{this.props.variable?.unit?.name !== "pc" && this.props?.variable?.weight &&*/}
                                                            {/*    <h6 className="sub-text">{"Estimated package weight: " + this.props?.variable?.weight + this.props?.variable?.unit?.name}</h6>*/}
                                                            {/*}*/}

                                                            <div className="col-12">
                                                                {realTimeoutStock ?
                                                                    <div className="buttons text-center">
                                                                        <div className="deliverable">
                                                                            <p className="">Out-of-stock</p>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="buttons text-center">
                                                                        {
                                                                            this.state.cartItems.length > 0 && this.props?.singleProduct && this.state.cartItems.find((item) => item.item_id === this.props?.singleProduct.item_id) ?
                                                                                (
                                                                                    <>
                                                                                        {this.state.cartItems
                                                                                            .filter(
                                                                                                (item) =>
                                                                                                    item.item_id === this.props?.singleProduct.item_id
                                                                                            ).slice(0,1)
                                                                                            .map((val) => {

                                                                                                // if (this.state.variable !== val.variable) {
                                                                                                //     this.setState({variable: val.variable});
                                                                                                // }
                                                                                                const allCartItems = this.state.cartItems.filter((item) => item.item_id === this.props?.singleProduct.item_id);
                                                                                                const totalItems = allCartItems.length;
                                                                                                var found = this.props.variables.find(o => parseInt(val.variable_id) === parseInt(o.id));
                                                                                                return (
                                                                                                    (this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                                                        <>
                                                                                                            <CartDropDown
                                                                                                                item={val.item_id}
                                                                                                                variables={this.props.variables || []}
                                                                                                                preferences={this.props.customizations || []}
                                                                                                                handleVariables={this.selectHandler}
                                                                                                                btnLabel={found ? `${totalItems} added` : "Add to cart"}
                                                                                                                isAddedToCart={true}
                                                                                                                cartValue={val}
                                                                                                                productName={this.props.singleProduct?.item_name}
                                                                                                                itemId={this.props.singleProduct?.item_id}
                                                                                                            />
                                                                                                            <div
                                                                                                                className="added"
                                                                                                                key={val.item_id}>
                                                                                                    <span
                                                                                                        className="minus"
                                                                                                        onClick={() => {
                                                                                                            if ((this.props.variables.length > 1 || !!this.props.customizations?.length)) {
                                                                                                                const ele = document.getElementById('dropdownButton' + this.props.singleProduct?.item_id);
                                                                                                                if (!!ele) {
                                                                                                                    ele.click();
                                                                                                                }
                                                                                                            } else {
                                                                                                                this.handleRemove(val?.cart_item_id)
                                                                                                            }
                                                                                                        }}>
                                                                                                              {(this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                                                                  <i className="fa fa-trash"></i>
                                                                                                                  :
                                                                                                                  <i className="fa fa-minus"></i>
                                                                                                              }
                                                                                                     </span>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        : <>

                                                                                                            <button
                                                                                                                className="btn btn-m cart-button menu-toggle"
                                                                                                                style={{overflow: 'visible'}}>
                                                                                                                <div
                                                                                                                    className="added"
                                                                                                                    key={val.item_id}>
                                                                                                    <span
                                                                                                        className={this.state.remove_disabled === true ? "minus disabled" : "minus"}
                                                                                                        onClick={() => {
                                                                                                            this.setState({removed_item: val.cart_item_id});
                                                                                                            if ((this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1 < 0) {
                                                                                                                this.setState({remove_disabled: true}, () => {
                                                                                                                    this.handleRemove(val.cart_item_id);
                                                                                                                })
                                                                                                            } else {
                                                                                                                this.addToCart({
                                                                                                                    'id': val.item_id,
                                                                                                                    'variable_id': val.variable_id,
                                                                                                                    'item_price': this.props.item_price,
                                                                                                                    'sell_price': this.props.sell_price,
                                                                                                                    'unit': this.props?.unit,
                                                                                                                    'weight': this.props?.weight,
                                                                                                                    'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1
                                                                                                                })
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                     <i className="fa fa-minus"></i>
                                                                                                </span>


                                                                                                                    <span>
                                                                                                         {this.state.remove_disabled === true ?
                                                                                                             <i className="fa fa-times"></i> : (this.state.product_quantity ? this.state.product_quantity : val.item_count) + " added"}
                                                                                                     </span>


                                                                                                                    <span
                                                                                                                        className={this.state.remove_disabled === true ? "plus disabled" : "plus"}
                                                                                                                        onClick={() => this.addToCart({
                                                                                                                            'id': val.item_id,
                                                                                                                            'variable_id': val.variable_id,
                                                                                                                            'item_price': this.props.item_price,
                                                                                                                            'sell_price': this.props.sell_price,
                                                                                                                            'unit': this.props?.unit,
                                                                                                                            'weight': this.props?.weight,
                                                                                                                            'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) + 1
                                                                                                                        })}
                                                                                                                    >
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                  </span>
                                                                                                                </div>
                                                                                                            </button>
                                                                                                        </>
                                                                                                )
                                                                                            })}
                                                                                    </>

                                                                                ) : (


                                                                                    (this.props.variables.length > 1 || !!this.props.customizations.length) ?
                                                                                        <>
                                                                                            <CartDropDown variables={this.props.variables || []}
                                                                                                          preferences={this.props.customizations || []}
                                                                                                          handleVariables={this.selectHandler}
                                                                                                          productName={this.props.singleProduct?.item_name}
                                                                                                          itemId={this.props.singleProduct?.item_id}
                                                                                            />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <button
                                                                                                className="btn btn-m cart-button menu-toggle"
                                                                                                style={{overflow: 'visible'}}
                                                                                                onClick={() => {
                                                                                                    if (parseInt(this.props.variables.length) === 1) {
                                                                                                        this.addToCart({
                                                                                                            'variable_id': this.props.variables[0].id,
                                                                                                            'item_price': this.props.variables[0].item_price,
                                                                                                            'sell_price': this.props.variables[0].sell_price,
                                                                                                            'unit': this.props.variables[0]?.unit?.name,
                                                                                                            'weight': this.props.variables[0]?.weight,
                                                                                                            'id': this.props.singleProduct.item_id,
                                                                                                            'quantity': 1
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                Add to cart
                                                                                            </button>
                                                                                        </>
                                                                                )}
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="col-12 mt-2 mb-2 text-center">
                                                                {this.state.isAdded === 0 ? (
                                                                    <>
                                                                        <div>
                                                                            <button
                                                                                className="btn btn-m btn-blank"
                                                                                onClick={this.handleClick}
                                                                                aria-describedby={id}
                                                                            >
                                                                                {this.state.mod === 0 || this.state.isAdded === 0
                                                                                    ? 'Add to list'
                                                                                    : 'Added'}
                                                                            </button>
                                                                            <Popover
                                                                                id={id}
                                                                                open={open}
                                                                                className={"pop-web"}
                                                                                anchorEl={this.state.anchorEl}
                                                                                onClose={this.handleClose}
                                                                                anchorOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                }}
                                                                                transformOrigin={{
                                                                                    vertical: 'top',
                                                                                    horizontal: 'center',
                                                                                }}
                                                                            >
                                                                                <ul>
                                                                                    {this.props.cookies.get('token') ?
                                                                                        this.state.list.length > 0 ?
                                                                                            this.state.list.map((val) => {
                                                                                                return (
                                                                                                    <li key={val.id}
                                                                                                        onClick={() => {
                                                                                                            this.props.cookies.set('list_type_id', val.id);
                                                                                                            this.handleList(this.props.singleProduct)
                                                                                                        }}>
                                                                                                        {val.title}
                                                                                                    </li>)
                                                                                            })
                                                                                            : null
                                                                                        :
                                                                                        <li onClick={() => this.handleList(this.props.singleProduct)}>My
                                                                                            List</li>
                                                                                    }
                                                                                </ul>
                                                                            </Popover>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <button className="btn btn-m btn-list">
                                                                        Added
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="col-12 col-lg-6 order-0 order-lg-2 mt-3 mt-lg-0">
                                                            <div className="single-pro product-details">
                                                                <div className="food-maintitle">
                                                                    <h1>{this.props.singleProduct?.item_name}</h1></div>
                                                                <div
                                                                    className="col-12 food-content d-inline-block px-0 py-md-2 py-0 mb-2 mb-md-0">
                                                                    <p className="price text-left mb-0 mt-1 px-0">
                                                                        {/*<sup>*/}
                                                                        {/*    {this.props.sell_price && this.props.item_price && Number(this.props.item_price) > Number(this.props.sell_price) ?*/}
                                                                        {/*        <del>${this.props.item_price ? Number(this.props.item_price).toFixed(2) : null} </del> : ""}*/}
                                                                        {/*</sup>*/}
                                                                        <span className="badge badge-default mb-2 mb-md-4">
                                                                            <span>{
                                                                                (this.state.unit ? this.state.unit : variableUnit) === "pc" ?
                                                                                    <>
                                                                                        Pieces:  {parseInt(this.state.weight ? this.state.weight : variableWeight)}
                                                                                    </>
                                                                                  :
                                                                                    <>
                                                                                        {/\d/.test(this.state.weight ? this.state.weight : variableWeight) ?
                                                                                            <>
                                                                                                Weight:  {this.state.weight ? this.state.weight : variableWeight}
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                {this.state.weight ? this.state.weight : variableWeight}
                                                                                           </>
                                                                                        }
                                                                                    </>
                                                                                 }
                                                                            </span>
                                                                            {(this.state.unit ? this.state.unit : variableUnit) !== "pc" && <span>{(this.state.unit ? this.state.unit : variableUnit)} </span>}
                                                                        </span>
                                                                    </p>
                                                                    {/*{this.props.sell_price && this.props.item_price && parseInt(this.props.item_price - this.props.sell_price) > 0 ?*/}
                                                                    {/*    <p className="price text-left p-0">*/}
                                                                    {/*    <span className="text-success">You save: ${this.props.sell_price ? Number(this.props.item_price - this.props.sell_price).toFixed(2) : null}</span></p>*/}
                                                                    {/*    : ""}*/}
                                                                </div>
                                                                <div className="d-md-block">
                                                                    {this.props.singleProduct.made_locally === 1 || this.props.singleProduct.is_prime === 1 || this.props.singleProduct.is_available_in_bulk === 1 ?
                                                                        <div
                                                                            className="col-12 food-content d-inline-block px-0 py-2">
                                                                            <div
                                                                                className="label-img d-flex align-items-center">
                                                                                {this.props.singleProduct?.is_prime === 1 &&
                                                                                <img
                                                                                    src={
                                                                                        images.imgP
                                                                                    }
                                                                                    alt="Prime product"
                                                                                />
                                                                                }

                                                                                {this.props.singleProduct?.made_locally === 1 &&
                                                                                <img
                                                                                    src={
                                                                                        images.imgH
                                                                                    }
                                                                                    alt="Local product"
                                                                                />
                                                                                }

                                                                                {this.props.singleProduct.is_available_in_bulk === 1 &&
                                                                                <img
                                                                                    src={
                                                                                        images.imgB
                                                                                    }
                                                                                    alt="Bulk product"
                                                                                />
                                                                                }

                                                                                {this.props.singleProduct?.made_locally === 1 || this.props.singleProduct.is_available_in_bulk === 1 || this.props.singleProduct.is_prime === 1 ? (
                                                                                    <>
                                                                                        <a className="icon-details-click d-block w-auto"
                                                                                           onClick={() => this.setState({show: true})}>Icon
                                                                                            details</a>
                                                                                    </>
                                                                                ) : null}

                                                                            </div>
                                                                            {/* <p className="price"><sup>$</sup><span>13</span><sup>33</sup> <del>$14.33</del></p> */}
                                                                        </div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.singleProduct?.item_description &&
                                                        <div
                                                            className="col-md-12 d-none d-lg-block  order-0 order-md-2 info-pro">
                                                            <span className="my-2 h5">Description</span>
                                                            <div className="desc" key={new Date().getTime()}
                                                               dangerouslySetInnerHTML={{__html: this.props.singleProduct ? this.props.singleProduct?.item_description : ""}}/>
                                                        </div>
                                                        }
                                                        <hr/>
                                                        <div className="mt-2 col-12 d-none info-pro align-items-center">
                                                            <b className="mr-1">Tags: </b>
                                                            <p className="mb-0">
                                                                {this.props.category && this.props.category?.length > 0 && this.props.category.map(item => (
                                                                    <span className="tags" key={item.id}>
                                                                    {item.category_name}
                                                                </span>
                                                                ))}
                                                                {/*{this.props.tags.map(item => (*/}
                                                                {/*    <span className="tags" key={item.id}>{item.tag}</span>*/}
                                                                {/*))}*/}
                                                            </p>
                                                        </div>
                                                        {/*<div className="col-12 block">*/}
                                                        {/*    <h3>Nutrition Facts</h3>*/}
                                                        {/*    <h6 className="font-weight-bold mt-3">Serving size</h6>*/}
                                                        {/*    <p>*/}
                                                        {/*        { this.state.nutrition.serving && this.state.nutrition.serving.length > 0 ?  this.state.nutrition.serving.map(item => (*/}
                                                        {/*            <span className="tags" key={item.id}>{item.key} : {item.value} </span>*/}
                                                        {/*        )) :  <span className="tags mb-2">Not available</span> }*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="col-12 block">*/}
                                                        {/*    <h6  className="font-weight-bold">Calories</h6>*/}
                                                        {/*    <p>*/}
                                                        {/*        {this.state.nutrition.calories && this.state.nutrition.calories.length > 0 ? this.state.nutrition.calories.map(item => (*/}
                                                        {/*            <span className="tags" key={item.id}>{item.key} : {item.value} </span>*/}
                                                        {/*        )) :  <span className="tags mb-2">Not available</span>  }*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}
                                                        {/*<div className="col-12 block">*/}
                                                        {/*    <h6 className="font-weight-bold">Daily Values</h6>*/}
                                                        {/*    <p>*/}
                                                        {/*        { this.state.nutrition.daily_values && this.state.nutrition.daily_values.length > 0 ?  this.state.nutrition.daily_values.map(item => (*/}
                                                        {/*            <span className="tags" key={item.id}>{item.key} : {item.value} ({item.percentage}%) </span>*/}
                                                        {/*        )) : <span className="tags mb-2">Not available</span> }*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            {/**************1111************/}
                                            {/* <Featured />*/}
                                        </div>
                                        {/*****RIGHT****/}
                                        <div className="col-12 col-lg-3 pl-0 single-pro-right-main z-9">
                                            <div
                                                className="col-12 bg-white main-section d-none d-md-block single-pro-right">
                                                <p className="price mb-0">
                                                    <span className="mt-0">
                                                        {this.props.sell_price && this.props.item_price && (this.props.item_price - this.props.sell_price) > 0 ?
                                                            <>
                                                                <del className="fs-100"> ${this.props.item_price ? Number(this.props.item_price).toFixed(2) : null}</del>
                                                                <span className="on-sale d-flex align-items-center justify-content-center">
                                                                    ${this.props.sell_price ? Number(this.props.sell_price).toFixed(2) : null}
                                                                    {/\d/.test(this.state.weight ? this.state.weight : variableWeight) &&
                                                                        <h6 className="mb-0 pl-1 pt-1 font-10 text-muted">(${this.state.price ? Number(this.state.price / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : variableAmount ? Number(variableAmount / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : null}{" ⁄ " + (this.state.unit ? this.state.unit : variableUnit)})</h6>
                                                                    }
                                                                </span>
                                                            </>
                                                            :
                                                            <span className="d-flex align-items-center justify-content-center">
                                                                ${isMultipleVariablesAdded ? Number(totalAmount).toFixed(2) : this.state.price ? Number(this.state.price).toFixed(2) : variableAmount ? Number(variableAmount).toFixed(2) : null}
                                                                {!isMultipleVariablesAdded &&
                                                                <>{/\d/.test(this.state.weight ? this.state.weight : variableWeight) &&
                                                                <h6 className="mb-0 pl-1 pt-1 font-10 text-muted">(${this.state.price ? Number(this.state.price / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : variableAmount ? Number(variableAmount / (this.state.weight ? this.state.weight : variableWeight)).toFixed(2) : null}{" ⁄ " + (this.state.unit ? this.state.unit : variableUnit)})</h6>
                                                                }</>
                                                                }
                                                            </span>
                                                        }
                                                    </span>
                                                </p>

                                                {this.props.sell_price && this.props.item_price && (this.props.item_price - this.props.sell_price) > 0 ?
                                                    <div className="col-12 food-content d-inline-block px-0 pb-1">
                                                        <p className="price">
                                                            <p className="text-success">You save:
                                                                ${this.props.sell_price ? Number(this.props.item_price - this.props.sell_price).toFixed(2) : null}</p>
                                                        </p>
                                                    </div>
                                                    : ""}

                                                {/*{this.props.variable?.unit?.name !== "pc" && this.props?.variable?.weight &&*/}
                                                {/*    <h6 className="sub-text">{"Estimated package weight: " + this.props?.variable?.weight + this.props?.variable?.unit?.name}</h6>*/}
                                                {/*}*/}

                                                <div className="col-12">

                                                    { realTimeoutStock ?
                                                        <div className="buttons text-center">
                                                            <div className="deliverable">
                                                                <p className="">Out-of-stock</p>
                                                            </div>
                                                        </div>
                                                       :
                                                       <><div className="buttons text-center">
                                                          {delivery_preference &&
                                                             <>
                                                            {this.state.cartItems.find((item) => item.item_id === this.props.singleProduct.item_id) ? (
                                                                <>
                                                                    {this.state.cartItems
                                                                        .filter((item) => item.item_id === this.props.singleProduct.item_id)
                                                                        .slice(0,1)
                                                                        .map((val) => {
                                                                            // if (this.state.variable !== val.variable) {
                                                                            //     this.setState({variable: val.variable});
                                                                            // }
                                                                            const allCartItems = this.state.cartItems.filter((item) => item.item_id === this.props.singleProduct.item_id);
                                                                            const totalItems = allCartItems.length;
                                                                            var found = this.props.variables.find(o => parseInt(val.variable_id) === parseInt(o.id));
                                                                            return (
                                                                                (this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                                    <>
                                                                                        <CartDropDown
                                                                                            item={val.item_id}
                                                                                            variables={this.props.variables || []}
                                                                                            preferences={this.props?.customizations || []}
                                                                                            handleVariables={this.selectHandler}
                                                                                            btnLabel={found ? `${totalItems} added` : "Add to cart"}
                                                                                            cartValue={val}
                                                                                            isAddedToCart={true}
                                                                                            productName={this.props.singleProduct?.item_name}
                                                                                            itemId={this.props.singleProduct?.item_id}
                                                                                        />
                                                                                        <div className="added"
                                                                                             key={val.id}>
                                                                                <span className="minus"
                                                                                      onClick={() => {
                                                                                          if ((this.props.variables.length > 1 || !!this.props.customizations?.length)) {
                                                                                              const ele = document.getElementById('dropdownButton' + this.props.singleProduct?.item_id);
                                                                                              if (!!ele) {
                                                                                                  ele.click();
                                                                                              }
                                                                                          } else {
                                                                                              this.handleRemove(val?.cart_item_id)
                                                                                          }
                                                                                      }}>
                                                                                    {(this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                                        <i className="fa fa-trash"></i>
                                                                                        :
                                                                                        <i className="fa fa-minus"></i>
                                                                                    }

                                                                                </span>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <button
                                                                                            className="btn btn-m cart-button menu-toggle"
                                                                                            style={{overflow: 'visible'}}
                                                                                        >
                                                                                            <div className="added"
                                                                                                 key={val.item_id}>

                                                                                            <span
                                                                                                className={this.state.remove_disabled === true ? "minus disabled" : "minus"}
                                                                                                onClick={() => {

                                                                                                    this.setState({removed_item: val.cart_item_id});

                                                                                                    if ((this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1 < 0) {
                                                                                                        this.setState({remove_disabled: true}, () => {
                                                                                                            this.handleRemove(val.cart_item_id);
                                                                                                        })
                                                                                                    } else {
                                                                                                        this.addToCart({
                                                                                                            'id': val.item_id,
                                                                                                            'variable_id': val.variable_id,
                                                                                                            'item_price': this.props.item_price,
                                                                                                            'sell_price': this.props.sell_price,
                                                                                                            'unit':  this.props.unit,
                                                                                                    'weight':  this.props.weight,
                                                                                                    'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    (this.state.product_quantity ? this.state.product_quantity : val.item_count) === 1 ?
                                                                                                        <i className="fa fa-trash"></i> :
                                                                                                        <i className="fa fa-minus"></i>
                                                                                                }
                                                                                            </span>

                                                                                                <span>
                                                                                       {
                                                                                           this.state.remove_disabled === true ?
                                                                                               <i className="fa fa-times"></i> : (this.state.product_quantity ? this.state.product_quantity : val.item_count) + " added"
                                                                                       }
                                                                                    </span>

                                                                                                <span
                                                                                                    className={this.state.remove_disabled === true ? "plus disabled" : "plus"}
                                                                                                    onClick={() =>
                                                                                                        this.addToCart({
                                                                                                            'id': val.item_id,
                                                                                                            'variable_id': val.variable_id,
                                                                                                            'item_price': this.props.item_price,
                                                                                                            'unit':  this.props.unit,
                                                                                                            'weight':  this.props.weight,
                                                                                                            'sell_price': this.props.sell_price,
                                                                                                            'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) + 1
                                                                                                        })}
                                                                                                >
                                                                                         <i className="fa fa-plus"></i>
                                                                                    </span>

                                                                                            </div>
                                                                                        </button>
                                                                                    </>
                                                                            )
                                                                        })}
                                                                </>
                                                            ) : (
                                                                (this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                    <>
                                                                        <CartDropDown variables={this.props.variables || []}
                                                                                      preferences={this.props.customizations || []}
                                                                                      handleVariables={this.selectHandler}
                                                                                      productName={this.props.singleProduct?.item_name}
                                                                                      itemId={this.props.singleProduct?.item_id}
                                                                        />
                                                                    </>

                                                                    : <button
                                                                        className="btn btn-m cart-button menu-toggle"
                                                                        style={{overflow: 'visible'}}
                                                                        onClick={() => {
                                                                            if (parseInt(this.props.variables.length) === 1) {
                                                                                this.addToCart({
                                                                                    'variable_id': this.props.variables[0].id,
                                                                                    'item_price': this.props.variables[0].item_price,
                                                                                    'sell_price': this.props.variables[0].sell_price,
                                                                                    'unit':  this.props.variables[0].unit?.name,
                                                                                    'weight':  this.props.variables[0].weight,
                                                                                    'id': this.props.singleProduct.item_id,
                                                                                    'quantity': 1
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        {this.state.product_quantity > 0 ?
                                                                            <i className="fa fa-check"></i> : "Add to cart"}
                                                                    </button>

                                                            )}

                                                        </>
                                                            }
                                                        {non_deliverable &&
                                                        <div className="deliverable">
                                                            <p className="">Available for Pickup Only</p>
                                                        </div>
                                                        }
                                                    </div></>
                                                    }
                                                </div>
                                                <div className="col-12 mt-2 mb-2 text-center">
                                                    {this.state.isAdded === 0 ? (
                                                        <>
                                                            <div>
                                                                <button
                                                                    className="btn btn-m btn-blank"
                                                                    onClick={this.handleClick}
                                                                    aria-describedby={id}
                                                                >
                                                                    {this.state.mod === 0 || this.state.isAdded === 0
                                                                        ? 'Add to list'
                                                                        : 'Added'}
                                                                </button>
                                                                <Popover
                                                                    id={id}
                                                                    open={open}
                                                                    className={"pop-mob"}
                                                                    anchorEl={this.state.anchorEl}
                                                                    onClose={this.handleClose}
                                                                    rit={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'center',
                                                                    }}
                                                                >
                                                                    <ul className="pop-true">
                                                                        {this.props.cookies.get('token') ?
                                                                            this.state.list.length > 0 ?
                                                                                this.state.list.map((val) => {
                                                                                    return (
                                                                                        <li key={val.id}
                                                                                            onClick={() => {
                                                                                                this.props.cookies.set('list_type_id', val.id);
                                                                                                this.handleList(this.props.singleProduct)
                                                                                            }}>
                                                                                            {val.title}
                                                                                        </li>)
                                                                                })
                                                                                : null
                                                                            :
                                                                            <li onClick={() => this.handleList(this.props.singleProduct)}>My
                                                                                List</li>
                                                                        }
                                                                    </ul>
                                                                </Popover>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <button className="btn btn-m btn-list">
                                                            Added
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="col-12 bg-white main-section single-pro-right py-3 d-block d-lg-none ">
                                                <div className="col-md-12 info-pro px-0">
                                                    <h3 className="my-2">Description</h3>
                                                    <div className="desc"
                                                       dangerouslySetInnerHTML={{__html: this.props.singleProduct?.item_description}}/>
                                                </div>
                                                <div className="single-product d-block d-lg-none">
                                                    <div className="single-pro product-details pt-0">
                                                        {this.props.singleProduct.is_prime === 1 || this.props.singleProduct.is_prime === 1 || this.props.singleProduct.is_available_in_bulk === 1 ?
                                                            <div
                                                                className="col-12 food-content d-inline-block px-0 pt-2">
                                                                <div className="label-img d-flex align-items-center">
                                                                    {this.props.singleProduct.is_prime === 1 ? (
                                                                        <img
                                                                            src={
                                                                                images.imgP
                                                                            }
                                                                            alt="Not Found"
                                                                        />
                                                                    ) : null}

                                                                    {this.props.singleProduct?.made_locally === 1 &&
                                                                    <img
                                                                        src={
                                                                            images.imgH
                                                                        }
                                                                        alt="Local product"
                                                                    />
                                                                    }

                                                                    {this.props.singleProduct.is_available_in_bulk === 1 ? (
                                                                        <img
                                                                            src={
                                                                                images.imgB
                                                                            }
                                                                            alt="Not Found"
                                                                        />
                                                                    ) : null}

                                                                    {this.props.singleProduct.is_available_in_bulk === 1 || this.props.singleProduct.is_prime === 1 ? (
                                                                        <>
                                                                            <a className="icon-details-click d-block w-auto"
                                                                               onClick={() => this.setState({show: true})}>Icon
                                                                                details</a>
                                                                        </>
                                                                    ) : null}

                                                                </div>
                                                                {/* <p className="price"><sup>$</sup><span>13</span><sup>33</sup> <del>$14.33</del></p> */}
                                                            </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<ul className="single-pro-options bg-white main-section">*/}
                                            {/*    <li>*/}
                                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">*/}
                                            {/*            <path fill="currentColor"*/}
                                            {/*                  d="M176 216h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16zm-16 80c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16H176c-8.84 0-16 7.16-16 16v16zm96 121.13c-16.42 0-32.84-5.06-46.86-15.19L0 250.86V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V250.86L302.86 401.94c-14.02 10.12-30.44 15.19-46.86 15.19zm237.61-254.18c-8.85-6.94-17.24-13.47-29.61-22.81V96c0-26.51-21.49-48-48-48h-77.55c-3.04-2.2-5.87-4.26-9.04-6.56C312.6 29.17 279.2-.35 256 0c-23.2-.35-56.59 29.17-73.41 41.44-3.17 2.3-6 4.36-9.04 6.56H96c-26.51 0-48 21.49-48 48v44.14c-12.37 9.33-20.76 15.87-29.61 22.81A47.995 47.995 0 0 0 0 200.72v10.65l96 69.35V96h320v184.72l96-69.35v-10.65c0-14.74-6.78-28.67-18.39-37.77z"*/}
                                            {/*                  className=""></path>*/}
                                            {/*        </svg>*/}
                                            {/*        <span>Email</span>*/}
                                            {/*    </li>*/}
                                            {/*    <li>*/}
                                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">*/}
                                            {/*            <path fill="currentColor"*/}
                                            {/*                  d="M448 192V77.25c0-8.49-3.37-16.62-9.37-22.63L393.37 9.37c-6-6-14.14-9.37-22.63-9.37H96C78.33 0 64 14.33 64 32v160c-35.35 0-64 28.65-64 64v112c0 8.84 7.16 16 16 16h48v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h48c8.84 0 16-7.16 16-16V256c0-35.35-28.65-64-64-64zm-64 256H128v-96h256v96zm0-224H128V64h192v48c0 8.84 7.16 16 16 16h48v96zm48 72c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z"*/}
                                            {/*                  className=""></path>*/}
                                            {/*        </svg>*/}
                                            {/*        <span>Print</span>*/}
                                            {/*    </li>*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                    {/*****RIGHT****/}
                                    {this.state.related_products.length > 0 &&
                                    <div className="single-related">
                                        <Related products={this.state.related_products}/>
                                    </div>
                                    }
                                    <div className="single-related mt-4">
                                        <RecentViewed selectedStore={this.props?.selectedStore || this.state.default_store}
                                                      product_id={this.props?.singleProduct?.item_id}/>
                                    </div>
                                </>
                            </div>
                        </div>
                    </>
                    :
                    <NotFound/>
                }
                <Modal
                    dialogClassName="modal-dialog-centered"
                    onHide={() => {
                        this.setState({show: false})
                    }} className="modal-info" show={this.state.show}>
                    <Modal.Body>
                        <div className="drop-modal pb-0">
                            <div className="order-prefrence">
                                <h4 className="card-heading">Icon details
                                    <span className="modal-close btn" onClick={() => {
                                        this.setState({show: false})
                                    }}>
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                             strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                             className="css-i6dzq1">
                                            <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18"
                                                                                             y2="18"></line></svg>
                                    </span>
                                </h4>
                            </div>
                            <ul>
                                {this.props.singleProduct?.is_prime === 1 ?
                                    <li className="w-100">
                                        <img src={images.imgP}
                                             alt="Not Found"
                                        />
                                        <h6 className="text-uppercase">Prime
                                            <span>This item is considered as prime.</span>
                                        </h6>
                                    </li>
                                    : ""}

                                {this.props.singleProduct?.is_available_in_bulk === 1 ?
                                    <li className="w-100">
                                        <img src={images.imgB}
                                             alt="Not Found"
                                        />
                                        <h6 className="text-uppercase">Bulk
                                            <span>This item is available for bulk order.</span>
                                        </h6>
                                    </li>
                                    : ""}

                                {this.props.singleProduct?.made_locally === 1 ?
                                    <li className="w-100">
                                        <img src={images.imgH}
                                             alt="Not Found"
                                        />
                                        <h6 className="text-uppercase">Local
                                            <span>This item is made locally</span>
                                        </h6>
                                    </li>
                                    : ""}
                            </ul>
                            {/*<button onClick={()=>{setShow(false)}}>Ok</button>*/}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.confirm_restaurant} className="storeModal">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 p-0">
                                <h2 className="card-heading w-100 mt-1 mb-3">
                                    Let’s confirm your Farmer’s location.
                                </h2>
                                <h6 className="w-100 mb-0 card-text">
                                    You’re currently shopping
                                    from <b>{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"}.</b> Is
                                    this the right location ?
                                </h6>
                            </div>
                            <hr/>
                            <div className="text-center">
                                <Button
                                    className="btn btn-s proceed-button mt-1"
                                    onClick={() => {
                                        this.setState({confirm_restaurant: false}, () => {
                                            this.props.cookies.set("change_store", false);
                                        });
                                    }}
                                >
                                    Continue shopping
                                    at <br/>{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"}
                                </Button>
                                <small className="my-2 d-inline-block w-100">OR</small>
                                <button
                                    className="btn btn-s cart-button"
                                    onClick={() => this.handleStoreChange()}
                                >
                                    Change location
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal onHide={() => {
                    this.setState({
                        offline: false
                    })
                }} show={this.state.offline} className="storeModal mt-5">
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="col-12 text-center p-0">
                                    <span className="">
                                          <img width="200px"
                                               src={images.offline}
                                          />
                                    </span>
                                <br/>
                                <h1 className="card-text w-100 font-weight-bold mt-3">{parseInt(this.state.default_store) === 2 ? "Farmer’s Fresh Meat at Mesa" : "Farmer’s Fresh Meat at Cullen"} </h1>
                            </div>
                            <hr/>
                            <div className="text-center">
                                <button onClick={() => {
                                    this.setState({product_quantity: 0, offline: false}, () => {
                                        this.props.cookies.set('selected_store', (parseInt(this.state.default_store) === 2 ? 1 : 2));
                                        this.props.updateCartItems({
                                            cart_id: this.props.cookies.get("cart_id")
                                                ? this.props.cookies.get("cart_id")
                                                : "blank",
                                            token: this.props.cookies.get("token")
                                                ? this.props.cookies.get("token")
                                                : null,
                                        });
                                    })
                                }} className="btn btn-s proceed-button mb-3 mb-md-0"
                                        style={{
                                            transition: 'all .3s ease-in-out'
                                        }}>
                                    {parseInt(this.state.default_store) === 2 ? "Continue, with Farmer’s Fresh Meat at Cullen" : "Continue, with Farmer’s Fresh Meat at Mesa"}
                                </button>
                                <button onClick={() => this.setState({offline: false, product_quantity: 0})}
                                        className="btn btn-s cart-button">Cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                      close={() => {
                                          this.setState({showDeliveryOfflineConflictModal: false})
                                      }}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        cartAddedItems: state.CartReducer.cartItems,
        list: state.ListReducer.userList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: 'UPDATE_CART_ITEMS', data: val})),
    }
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SingleProduct))));
