import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import API from "../../api";
import {Cookies, withCookies} from "react-cookie";
import {withSnackbar} from "notistack";
import {instanceOf} from "prop-types";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {withRouter} from "react-router-dom";
import CateRelated from "../Home/children/cateRelated";
import NotFound from "../Static/NotFound";
import {updateCart} from "../../actions/cart";
import {connect} from "react-redux";
import {confirmAlert} from "react-confirm-alert";
import {SideBySideMagnifier} from "react-image-magnifiers";
import ImageGallery from "react-image-gallery";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import "lightgallery.js/dist/css/lightgallery.css";
import {images} from "../../constant";
import DeliveryOfflineModal from "./DeliveryOfflineModal";
import {limitToLast, onChildAdded, query, ref} from "@firebase/database";
import db from "../../../firebase";
import cookie from "cookie";
import Dropdown from "react-bootstrap/Dropdown";
import CartDropDown from "./CartDropDown";

let timerId;
const api = new API();

class SingleCatering extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            cooking_option: null,
            show: false,
            removed: 0,
            variables: [],
            cookings: [],
            images: [],
            category: [],
            singleProduct: null,
            cartItems: [],
            isAdded: 0,
            sell_price: null,
            item_price: null,
            variable_id: null,
            showDeliveryOfflineConflictModal : false,
            product_id: null,
            variable: null,
            selected_variable: null,
            anchorEl: null,
            nutrition: {
                serving: [], calories: [], daily_values: []
            },
            selectedImage: null,
            default_store: this.props.cookies.get('selected_store') !== undefined ? this.props.cookies.get('selected_store') : 2,
            default_preference: this.props.cookies.get('selected_preference') ? this.props.cookies.get('selected_preference') : 1, //Pickup
            related_products: [],
            image_index: 0,
            firebase_stock : null,
            restaurant_items : []
        };
        this.selectHandler = this.selectHandler.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleRedirection = this.handleRedirection.bind(this);
    }

    static async getInitialProps(ctx) {

        const { req , match } = ctx;

        let selectedStore = null;
        if (req && req.headers && req.headers.cookie) {
            // On the server-side, you can access cookies using headers.cookie
            const cookies = cookie.parse(req.headers.cookie);
            selectedStore = cookies?.selected_store || 2; //Default mesa;
        } else if (typeof window !== 'undefined') {
            // On the client-side, you can access cookies using document.cookie
            const cookies = cookie.parse(document.cookie);
            selectedStore = cookies?.selected_store || 2; //Default mesa;
        }
        const singeProduct = await api.getSingleCateringProduct(match.params.product_id,selectedStore);
        if (singeProduct.data.response !== null) {
            return {
                found: 1,
                sell_price: singeProduct.data.response.item_variables[0].sell_price ? singeProduct.data.response.item_variables[0].sell_price : null,
                item_price: singeProduct.data.response.item_variables[0].item_price ? singeProduct.data.response.item_variables[0].item_price : null,
                variable_id: singeProduct.data.response.item_variables[0].id ? singeProduct.data.response.item_variables[0].id : null,
                variables: singeProduct.data.response.item_variables,
                cookings: singeProduct.data.response.cooking,
                category: singeProduct.data.response.categories,
                images: singeProduct.data.response.images.length ? singeProduct.data.response.images.map((image) => {
                    return ({original: `${image.path}`, thumbnail:  `${  "https://image.qualwebs.com/200x200/" + image.path}`})
                }) : [ { original : images.food, thumbnail : images.food }],
                mobile_images: singeProduct.data.response.images.length ? singeProduct.data.response.images.map((image) => {
                    return image.path;
                }) : [images.food],
                customizations: singeProduct.data.response?.customizations || [],
                singleProduct: singeProduct.data.response,
                variable: singeProduct.data.response.item_variables[0],
                match: match,
                restaurant_items : singeProduct.data.response.restaurant_items,
            };
        } else {
            return {
                found: 0
            }
        }
    }

    handleRedirection(path) {
        this.props.history.push(path);
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    getRelatedCateringProducts() {
        api.getFilterCateringProduct(this.state.default_store).then(products => {
            this.setState({
                related_products: products.data.response
            })
        });
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.getRelatedCateringProducts();

        if (Object.keys(this.props.cartAddedItems).length !== 0 && this.props.cartAddedItems?.cart_items?.length !== this.state.cartItems?.length) {
            this.setState({cartItems: this.props?.cartAddedItems?.cart_items});
        }

        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')});
        }

        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }

        this.firebaseLastSnap();
    }

    componentDidUpdate(nextProps, prevProps) {

        if (Object.keys(this.props.cartAddedItems).length !== 0 && this.props.cartAddedItems?.cart_items && this.props.cartAddedItems?.cart_items !== prevProps?.cart_items
            && this.props.cartAddedItems?.cart_items !== this.state.cartItems) {
            if (this.state.remove_disabled === true && this.state.product_quantity === null) {
                this.setState({remove_disabled: false})
            }
            this.setState({cartItems: this.props.cartAddedItems?.cart_items});
        }
        if (this.props.cookies.get('selected_preference') !== undefined && this.props.cookies.get('selected_preference') !== this.state.default_preference) {
            this.setState({default_preference: this.props.cookies.get('selected_preference')});
        }
        if (this.props.cookies.get('selected_store') !== undefined && this.props.cookies.get('selected_store') !== this.state.default_store) {
            this.setState({default_store: this.props.cookies.get('selected_store')},()=>{
                this.getSingleProduct(this.props.match.params.product_id);
            });
        }
    }

    firebaseLastSnap() {
        const newMessageRef = query(ref(db, `item_stock_logs`),
            limitToLast(1)
        );
        onChildAdded(newMessageRef, (snapshot) => {
            const data = snapshot.val();
            if(data?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ) {
                this.setState({firebase_stock: data})
            }
        });
    }

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    selectHandler(event, customize = '') {
        var item = JSON.parse(event.target.value);
        this.setState({
            selected_variable: item.id,
            sell_price: item.sell_price,
            item_price: item.item_price
        }, () => {
            this.addToCart({
                'variable_id': item.id,
                'item_price': item.item_price,
                'sell_price': item.sell_price,
                'id': item.item_id,
                'quantity': item.item_count ? item.item_count : 1,
                customize: customize === 'No preference' ? '' : customize,
            });
        });

    }

    handleRemove(item_id) {
        api.removeFromCart(item_id).then(success => {
            this.props.updateCartItems({
                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null
            },()=>{
                this.setState({
                    selected_variable: null,
                    sell_price: null,
                    item_price: null
                });
            });
        });
    };

    addToCart(val) {

        var time = 300;

        var allowRequest = true;
        if (val.quantity <= 0) {
            this.setState({product_quantity: null, remove_disabled: true}, () => {
                this.handleRemove(this.state.removed_item);
            });
            allowRequest = false;
        } else {
            this.setState({product_quantity: val.quantity});
        }

        clearTimeout(timerId);

        //api calling with 500ms delay
        timerId = setTimeout(
            function () {

                if (allowRequest === true && this.state.product_quantity !== null) {
                    this.setState({
                        confirm_restaurant: this.props.cookies.get("change_store") &&
                        this.props.cookies.get("change_store") === "false"
                            ? false
                            : true,
                    });

                    this.setState({sell_price: val.sell_price, item_price: val.item_price});

                    const product = {
                        'menu_id': 3,
                        'restaurant': this.state.default_store,
                        "item_id": val.id,
                        "variable_id": val.variable_id,
                        "quantity": this.state.product_quantity,
                        customization: val.customize === 'No preference' ? '' : (val.customize || ''),
                    };

                    api.addToCart(product,
                        this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                        this.props.cookies.get('token') ? this.props.cookies.get('token') : null,
                        this.state.default_preference
                    ).then(success => {

                        if (success.data.status == "400") {
                            this.setState({errors: {}});
                            this.props.enqueueSnackbar(success.data.message, {
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                                variant: 'error',
                            });
                        } else if (success.data.status == "402") {
                            confirmAlert({
                                title: 'Other restaurant product already exist',
                                message: 'Are you sure, want to  add this.',
                                buttons: [
                                    {
                                        label: 'Yes',
                                        onClick: () => {
                                            this.setState({removed: 1}, () => {
                                                this.addToCart(val)
                                            });
                                        }
                                    },
                                    {
                                        label: 'No',
                                    }
                                ]
                            });
                        } else if (success.data.status == "405") {
                            this.setState({showDeliveryOfflineConflictModal : true})
                        } else {

                            this.props.cookies.set('cart_id', success.data.response);
                            this.setState({removed: 0});
                            this.props.updateCartItems({
                                cart_id: this.props.cookies.get('cart_id') ? this.props.cookies.get('cart_id') : 'blank',
                                token: this.props.cookies.get('token') ? this.props.cookies.get('token') : null
                            });
                        }
                    }).catch(error => {
                        if (error?.response?.data?.response?.is_grocery_online === 0) {
                            this.setState({offline: true});
                        } else {
                            this.props.enqueueSnackbar(error?.response?.data?.message, {
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "error",
                            });
                        }
                    });
                }
            }
                .bind(this),
            time
        );
    }

    handleImageClick() {
        this.setState({isOpen: true});
    }

    getSingleProduct(data) {
        api.getSingleCateringProduct(data,this.state.default_store).then(products => {
            if (products.data.response != null) {
                this.setState({
                    sell_price: products.data.response.item_variables[0].sell_price ? products.data.response.item_variables[0].sell_price : null,
                    item_price: products.data.response.item_variables[0].item_price ? products.data.response.item_variables[0].item_price : null,
                    variable_id: products.data.response.item_variables[0].id ? products.data.response.item_variables[0].id : null,
                    variables: products.data.response.item_variables,
                    cookings: products.data.response.cooking,
                    category: products.data.response.categories,
                    images: products.data.response.images.length ? products.data.response.images.map((image) => {
                        return ({original: `${image.path}`, thumbnail: `${ "https://image.qualwebs.com/200x200/" + image.path}`})
                    }) : [ { original : images.food, thumbnail : images.food }],
                    mobile_images: products.data.response.images.length ? products.data.response.images.map((image) => {
                        return image.path;
                    }) : [images.food],
                    singleProduct: products.data.response,
                    variable: products.data.response.item_variables[0],
                    restaurant_items : products.data.response.restaurant_items,
                });
                this.getRelatedCateringProducts();
            }
        })
    }

    WeightPrice(price, item_price, variable_id) {
        this.setState({sell_price: price, item_price: item_price, variable_id: variable_id});
    }

    render() {

        const delivery_preference = parseInt(this.state.default_preference) === 1 || (parseInt(this.state.default_preference) === 2 && this.props?.singleProduct?.is_deliverable === 1);
        const non_deliverable = parseInt(this.state.default_preference) === 2 && this.props?.singleProduct?.is_deliverable === 0;

        const variables = this.state?.variables.length ? this.state?.variables : this.props?.variables;

        const price_range = variables?.length ? variables.filter(product => product.status === 1)
                .map(product => product.sell_price)
                .filter(price => price !== undefined)
            : [];

        const allCartItems = this.props?.cartAddedItems?.cart_items ? this.props?.cartAddedItems?.cart_items?.filter((item) => item.item_id === this.props?.singleProduct?.item_id) : [];
        const min = price_range.length ? Number(Math.min(...price_range)).toFixed(2) : 0;
        const totalAmount = !!allCartItems.length ? allCartItems.reduce((total, a) => Number(a.total_amount) + Number(total), 0) : min ;

        var cookings = this.state.cookings.length > 0 ? this.state.cookings : this.props?.cookings;

        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        var mobileView = false;
        {
            if (typeof window !== "undefined") {
                if (window.innerWidth < 768) {
                    mobileView = true;
                }
            }
        }

        const realTimeoutStock =  (this.state.firebase_stock &&  this.state.firebase_stock?.item_id == this.props?.singleProduct.item_id && (this.state.firebase_stock?.restaurant_id == this.state.default_store || this.state.firebase_stock?.restaurant_id == 0 ))
            ?  this.state.firebase_stock?.status == 0 : ( this.state.restaurant_items?.length > 0 ? (this.state.restaurant_items?.[0]?.status !== 1) : (this.props.restaurant_items?.length > 0 && this.props?.restaurant_items?.[0]?.status !== 1));

        return (<React.Fragment>

            {this.props?.found === 1 ?
                <div className="container container-margin">
                    <div className="col-12 space z-9 position-relative">
                        <>
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <div className="breadcrumb-details">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb pb-1">
                                                <li className="breadcrumb-item"
                                                    onClick={() => this.handleRedirection('/')}><a
                                                    href="javascript:void(0)">Farmer's Fresh Meat</a></li>
                                                <li className="breadcrumb-item"
                                                    onClick={() => this.handleRedirection('/catering')}><a
                                                    href="javascript:void(0)">Catering</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">
                                                    <a>{this.state.singleProduct ? this.state.singleProduct.item_name : this.props?.singleProduct?.item_name}</a>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-9 col-xl-9">
                                    {/*************1*************/}
                                    <div className="bg-white mt-0 p-0 single-product main-section">
                                        <div className="product-details sale-banner">
                                            {(this.state.singleProduct ? this.state.singleProduct.is_on_sell : this.props?.singleProduct?.is_on_sell) == "1" ? (
                                                <div className="onsale-details">
                                                    <span>On sale</span>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 order-2 order-md-1 col-md-6">
                                                    <div
                                                        id="myCarousel"
                                                        className="carousel slide"
                                                    >
                                                        {/*<!-- main slider carousel items -->*/}
                                                        <div>
                                                            {this.props?.images.length > 1 ?
                                                                <>
                                                                    <div
                                                                        className="col-12 d-none d-md-block p-0">
                                                                        <SideBySideMagnifier
                                                                            className={"img-overlay custom-cursor"}
                                                                            imageSrc={
                                                                                "https://image.qualwebs.com/200x200/" + this.props?.images[this.state.image_index]?.original
                                                                            }
                                                                            imageAlt={this.props.singleProduct?.item_name}
                                                                            largeImageSrc={
                                                                                "https://image.qualwebs.com/900x900/" + this.props?.images[this.state.image_index]?.original
                                                                            }
                                                                            zoomContainerBorder="1px solid #ccc"
                                                                            zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                                            fillAvailableSpace={true}
                                                                            fillAlignTop={0}
                                                                            fillGapTop={130}
                                                                            fillGapBottom={50}
                                                                            fillGapRight={100}
                                                                            fillGapLeft={25}
                                                                            overlayOpacity={0.2}
                                                                        />
                                                                    </div>

                                                                    <div
                                                                        className="col-12 d-block d-md-none p-0">
                                                                        <div className="col-12 p-0"
                                                                             onClick={(e) => {
                                                                                 this.handleImageClick(e)
                                                                             }}>
                                                                            <img className="img-fluid"
                                                                                 src={this.props?.images[this.state.image_index]?.original}/>
                                                                        </div>
                                                                    </div>

                                                                    <ImageGallery infinite={true}
                                                                                  showFullscreenButton={false}
                                                                                  disableKeyDown={true}
                                                                                  showNav={false}
                                                                                  showPlayButton={false}
                                                                                  slideOnThumbnailOver={true}
                                                                                  onSlide={(event) => {
                                                                                      this.setState({image_index: event})
                                                                                  }}
                                                                                  items={this.props?.images}/>

                                                                </>
                                                                :
                                                                this.props?.images.length > 0 && this.props?.images.map((item, index) => (
                                                                    {
                                                                        ...mobileView ?

                                                                            <>
                                                                                <div
                                                                                    className="col-12 d-block d-md-none p-0">
                                                                                    <div className="col-12 p-0"
                                                                                         onClick={(e) => {
                                                                                             this.handleImageClick(e)
                                                                                         }}>
                                                                                        <img
                                                                                            className="img-fluid"
                                                                                            src={item?.original}/>
                                                                                    </div>
                                                                                </div>
                                                                            </>

                                                                            :
                                                                            <SideBySideMagnifier
                                                                                imageSrc={
                                                                                    item.original
                                                                                }
                                                                                imageAlt={this.props.singleProduct?.item_name}
                                                                                largeImageSrc={
                                                                                    "https://image.qualwebs.com/900x900/" + item.original
                                                                                }
                                                                                zoomContainerBorder="1px solid #ccc"
                                                                                zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
                                                                                fillAvailableSpace={true}
                                                                                fillAlignTop={0}
                                                                                fillGapTop={130}
                                                                                fillGapBottom={50}
                                                                                fillGapRight={100}
                                                                                fillGapLeft={25}
                                                                                overlayOpacity={0.2}
                                                                            />
                                                                    }
                                                                ))
                                                            }
                                                            {this.state.isOpen &&
                                                                <Lightbox
                                                                mainSrc={this.props?.mobile_images[this.state.image_index]}
                                                                nextSrc={this.props?.mobile_images[(this.state.image_index + 1) % this.props?.mobile_images.length]}
                                                                prevSrc={this.props?.mobile_images[(this.state.image_index + this.props?.mobile_images.length - 1) % this.props?.mobile_images.length]}
                                                                onCloseRequest={() => this.setState({
                                                                    isOpen: false,
                                                                    image_index: 0
                                                                })}
                                                                onMovePrevRequest={() =>
                                                                    this.setState({
                                                                        image_index: (this.state.image_index + this.props?.mobile_images.length - 1) % this.props?.mobile_images.length,
                                                                    })
                                                                }
                                                                onMoveNextRequest={() =>
                                                                    this.setState({
                                                                        image_index: (this.state.image_index + 1) % this.props?.mobile_images.length,
                                                                    })
                                                                }
                                                            />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 order-1 order-md-2 col-md-6">
                                                    <div className="single-pro product-details">
                                                        <div className="food-maintitle">
                                                            <h1>{this.state.singleProduct ? this.state.singleProduct?.item_name : this.props?.singleProduct?.item_name}</h1>
                                                        </div>
                                                        <div className="col-12 food-content d-inline-block px-0 py-0">
                                                            <h6 className="price text-left mt-1 px-0"><sup></sup>
                                                                <span className="mr-1">${this.state.sell_price ? Number(this.state.sell_price).toFixed(2) : Number(this.props.sell_price).toFixed(2)}</span>
                                                                {this.state.item_price && this.state.sell_price && this.state.item_price > this.state.sell_price ?
                                                                    <del>${this.state.item_price ? Number(this.state.item_price).toFixed(2) : null} </del>
                                                                    : this.props.item_price && this.props.sell_price && this.props.item_price > this.props.sell_price ?
                                                                        <del>${this.props.item_price ? Number(this.props.item_price).toFixed(2) : null} </del>
                                                                        : ""}
                                                            </h6>
                                                            {this.state.item_price !== this.state.sell_price && parseInt(this.state.item_price - this.state.sell_price) > 0 &&
                                                                <div
                                                                    className="col-12 food-content d-inline-block px-0 pb-1">
                                                                    <p className="price px-0">
                                                                        <p className="text-success px-0">You save:
                                                                            ${this.state.sell_price ? Number(this.state.item_price - this.state.sell_price).toFixed(2) : null}</p>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="row">
                                                            {/** */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 block info-pro">
                                                    <h3 className="my-2">Description</h3>
                                                    <div className="desc mb-2" key={new Date().getTime()}
                                                       dangerouslySetInnerHTML={{__html: this.state.singleProduct ? this.state.singleProduct?.item_description : this.props?.singleProduct?.item_description}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {cookings.length > 0 &&
                                        <div className="bg-white mt-0 p-0 single-product main-section pb-3">
                                            <div className="col-12">
                                                <h3 className="text-left mt-4 mb-1">Serving</h3>
                                                <div className="row">
                                                    {cookings.length > 0 ? cookings.map(item => {
                                                        return (<div className="col-md-4 my-2"
                                                                     onClick={() => this.setState({cooking_option: item.title}, () => {
                                                                         this.props.cookies.set(
                                                                             "cooking_option",
                                                                             item.title
                                                                         );
                                                                     })}
                                                        >
                                                            <div
                                                                className={this.state.cooking_option === item.title ? "card col-md-12 m-0 card-color" : "card col-md-12 m-0"}>
                                                                <div className="item-image-section single-d mt-2">
                                                                    <img className="card-img-top single-d"
                                                                         src={item.image}/>
                                                                </div>
                                                                <div className="card-body text-center p-2">
                                                                    <h6 className="">{item.title}</h6>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                    }) : ""}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {variables.length > 0 &&
                                        <div className="bg-white mt-0 p-0 single-product main-section pb-3">
                                            <div className="col-12">
                                                <h3 className="text-left mt-4 mb-1">What's the event size?</h3>
                                                <div className="col-12 px-2">
                                                    <div className="row">
                                                        {variables.length > 0 ? variables.map(item => {
                                                            const checked = this.state.cartItems?.find(val => val.variable_id === item.id);
                                                            if (item.status === 1) {
                                                                return (<div className="col-md-4 col-6 my-2 px-2"
                                                                             onClick={() => this.setState({
                                                                                 selected_variable: item.id,
                                                                                 sell_price: item.sell_price,
                                                                                 item_price: item.item_price
                                                                             })}>
                                                                    <div
                                                                        className={checked ? "card col-md-12 m-0 card-color p-md-3 py-3 px-2" : "card col-md-12 m-0 p-md-3 py-3 px-2"}>
                                                                        <div
                                                                            className="card-body catering-card text-center ">
                                                                            <h6 className="text-uppercase">{item.tray_size}<br/><span>Sized Tray</span>
                                                                            </h6>
                                                                            <p className="mb-0">Top: {item.top_size}</p>
                                                                            <p className="mb-0">Bottom: {item.bottom_size}</p>
                                                                            <small>Feeds: {item.feed_min}-{item.feed_max} people</small>
                                                                            <p className="card-text price text-center">
                                                                                <sup>${item ? Number(item.sell_price).toFixed(2) : 0}</sup>
                                                                                {" "}
                                                                                {item && item.item_price > item.sell_price ?
                                                                                    <del>${item ? Number(item.item_price).toFixed(2) : 0}</del>
                                                                                    : ""}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>)
                                                            }
                                                        }) : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/*****RIGHT****/}
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 pl-0 single-pro-right-main">
                                    <div className="col-12 bg-white main-section single-pro-right">
                                        <p className="price mb-0">
                                            {
                                                this.state.selected_variable ?
                                                    <span>${this.state.sell_price ? Number(this.state.sell_price).toFixed(2) : null}</span> :
                                                    <p className="price"><span>${Number(!!allCartItems.length ? allCartItems.reduce((total, a) => Number(a.total_amount) + Number(total), 0) : min).toFixed(2)}</span></p>
                                            }
                                        </p>
                                        {(this.state.item_price ? this.state.item_price : this.props.item_price) !== (this.state.sell_price ? this.state.sell_price : this.props.sell_price) ?
                                            <div
                                                className="col-12 food-content d-inline-block px-0 pb-1">
                                                <p className="price px-0">
                                                    <p className="text-success px-0">You save:
                                                        ${this.state.sell_price ? Number(this.state.item_price - this.state.sell_price).toFixed(2) : Number(this.props.item_price - this.props.sell_price).toFixed(2)}</p>
                                                </p>
                                            </div>
                                            : ""}

                                        <div className="col-12">
                                            {realTimeoutStock ?
                                                    <div className="buttons text-center">
                                                    <div className="deliverable">
                                                        <p className="">Out-of-stock</p>
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    {delivery_preference &&
                                                        <div className="buttons text-center">
                                                        {this.state.cartItems.length > 0 && this.props?.singleProduct && this.state.cartItems.find((item) => item.item_id === this.props?.singleProduct.item_id) ?
                                                            (
                                                                <>
                                                                    {
                                                                    this.state.cartItems
                                                                        .filter(
                                                                            (item) =>
                                                                                item.item_id === this.props?.singleProduct.item_id
                                                                        ).slice(0,1)
                                                                        .map((val) => {

                                                                            // if (this.state.variable !== val.variable) {
                                                                            //     this.setState({variable: val.variable, selected_variable : val?.variable?.id, sell_price : val?.variable?.sell_price, item_price : val?.variable?.item_price});
                                                                            // }
                                                                            const allCartItems = this.state.cartItems.filter((item) => item.item_id === this.props?.singleProduct.item_id);
                                                                            const totalItems = allCartItems.length;
                                                                            var found = this.props.variables.find(o => parseInt(val.variable_id) === parseInt(o.id));
                                                                            return (
                                                                                (this.props.variables.length > 1 || !!this.props.customizations.length) ?
                                                                                    <>
                                                                                        <CartDropDown
                                                                                                      item={val.item_id}
                                                                                                      variables={this.props.variables || []}
                                                                                                      preferences={this.props.customizations || []}
                                                                                                      handleVariables={this.selectHandler}
                                                                                                      btnLabel={found ? `${totalItems} added` : "Add to cart"}
                                                                                                      cartValue={val}
                                                                                                      isCatering={true}
                                                                                                      isAddedToCart={true}
                                                                                                      productName={this.props.singleProduct?.item_name}
                                                                                                      itemId={this.props.singleProduct?.item_id}
                                                                                        />
                                                                                        <div
                                                                                            className="added"
                                                                                            key={val.item_id}>
                                                                                                    <span
                                                                                                        className="minus"
                                                                                                        onClick={() => {
                                                                                                            if((this.props.variables.length > 1 || !!this.props.customizations?.length)) {
                                                                                                                const ele = document.getElementById('dropdownButton' + this.props.singleProduct?.item_id);
                                                                                                                if(!!ele) {
                                                                                                                    ele.click();
                                                                                                                }
                                                                                                            } else {
                                                                                                                this.handleRemove(val?.cart_item_id)
                                                                                                            }
                                                                                                        }}>
                                                                                                               {(this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                                                                   <i className="fa fa-trash"></i>
                                                                                                                   :
                                                                                                                   <i className="fa fa-minus"></i>
                                                                                                               }
                                                                                                     </span>
                                                                                        </div>
                                                                                    </>
                                                                                    : <>

                                                                                        <button
                                                                                            className="btn btn-m cart-button menu-toggle"
                                                                                            style={{overflow: 'visible'}}>
                                                                                            <div
                                                                                                className="added"
                                                                                                key={val.item_id}>
                                                                                                    <span
                                                                                                        className={this.state.remove_disabled === true ? "minus disabled" : "minus"}
                                                                                                        onClick={() => {
                                                                                                            this.setState({removed_item: val.cart_item_id});
                                                                                                            if ((this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1 < 0) {
                                                                                                                this.setState({remove_disabled: true}, () => {
                                                                                                                    this.handleRemove(val.cart_item_id);
                                                                                                                })
                                                                                                            } else {
                                                                                                                this.addToCart({
                                                                                                                    'id': val.item_id,
                                                                                                                    'variable_id': val.variable_id,
                                                                                                                    'item_price': this.props.item_price,
                                                                                                                    'sell_price': this.props.sell_price,
                                                                                                                    'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) - 1
                                                                                                                })
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                     <i className="fa fa-minus"></i>
                                                                                                </span>


                                                                                                <span>
                                                                                                {this.state.remove_disabled === true ?
                                                                                                    <i className="fa fa-times"></i> : (this.state.product_quantity ? this.state.product_quantity : val.item_count) + " added"}
                                                                                            </span>

                                                                                                <span
                                                                                                    className={this.state.remove_disabled === true ? "plus disabled" : "plus"}
                                                                                                    onClick={() => this.addToCart({
                                                                                                        'id': val.item_id,
                                                                                                        'variable_id': val.variable_id,
                                                                                                        'item_price': this.props.item_price,
                                                                                                        'sell_price': this.props.sell_price,
                                                                                                        'quantity': (this.state.product_quantity ? this.state.product_quantity : val.item_count) + 1
                                                                                                    })}
                                                                                                >
                                                                                                        <i className="fa fa-plus"></i>
                                                                                                  </span>
                                                                                            </div>
                                                                                        </button>
                                                                                    </>
                                                                            )
                                                                        })}
                                                                </>

                                                            ) : (
                                                                (this.props.variables.length > 1 || !!this.props.customizations?.length) ?
                                                                    <>
                                                                        <CartDropDown
                                                                                      item={this.props.item_id}
                                                                                      variables={this.props.variables || []}
                                                                                      preferences={this.props.customizations || []}
                                                                                      handleVariables={this.selectHandler}
                                                                                      isCatering={true}
                                                                                      productName={this.props.singleProduct?.item_name}
                                                                                      itemId={this.props.singleProduct?.item_id}
                                                                        />
                                                                    </>

                                                                    :

                                                                    <>
                                                                        <button
                                                                            className="btn btn-m cart-button menu-toggle"
                                                                            style={{overflow: 'visible'}}
                                                                            onClick={() => {
                                                                                if (parseInt(this.props.variables.length) === 1) {
                                                                                    this.addToCart({
                                                                                        'variable_id': this.props.variables[0].id,
                                                                                        'item_price': this.props.variables[0].item_price,
                                                                                        'sell_price': this.props.variables[0].sell_price,
                                                                                        'id': this.props.singleProduct.item_id,
                                                                                        'quantity': 1
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            Add to cart
                                                                        </button>
                                                                    </>
                                                            )}
                                                    </div>
                                                    }
                                                    {non_deliverable &&
                                                        <div className="deliverable">
                                                        <p className="">Available for Pickup Only</p>
                                                    </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                    {this.state?.related_products?.length > 0 &&
                        <div className="col-12 single-related">
                            <CateRelated products={this.state?.related_products} product_id={this.props?.singleProduct?.item_id}/>
                        </div>
                    }
                </div>
                :
                <NotFound/>
            }
            <DeliveryOfflineModal show={this.state.showDeliveryOfflineConflictModal}
                                  close={() => {
                                      this.setState({showDeliveryOfflineConflictModal: false})
                                  }}
            />
        </React.Fragment>);
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        cartAddedItems: state.CartReducer.cartItems,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCartItems: (val) => dispatch(updateCart({type: 'UPDATE_CART_ITEMS', data: val})),
    }
};

export default withRouter(withCookies(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SingleCatering))));


